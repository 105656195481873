//--------------------------------------------------
//Verification Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const VerificationPage = ({ VerifyPassword, clearance, error }) => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('verification');
    
        return () => {
          document.body.classList.remove('verification');
        };
    }, []);

    const handlePasswordSubmit = () => {
        VerifyPassword(password);
    };

    useEffect(() => {
        if (clearance === 'clear') {
            navigate('/'); 
        }
    }, [clearance, navigate]);

    return (
        <Fragment>
            <Container fluid className='verification-container'>
                <Container fluid className='password-enter'>
                    <Row className='justify-content-center'>
                        <Col md={7}>
                            <h2>Welcome,<br/>Enter Password</h2>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md={7}>
                            <Input.Password
                                className='username-password-input'
                                placeholder="Password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onPressEnter={handlePasswordSubmit}
                            />
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md={7}>
                            {error && <div className='error-message'>{error}</div>}
                            <br/>
                            <button className='verification-button' onClick={handlePasswordSubmit}>Submit</button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session,
        clearance: state.session.clearance,
        error: state.session.error,
     };
}

const actionCreators = {
    VerifyPassword: userActions.VerifyPassword,
};

const connection = connect(mapState, actionCreators)(VerificationPage);
export { connection as VerificationPage };