let waterUseData = [
    { county: 'ADA', type: 'Irrigation Agriculture', '1985': 0.00, '1990': 0.00, '1995': 0.00, '2000': 180.88, '2005': 192.32, '2010': 117.90, '2015': 108.48 },
    { county: 'ADA', type: 'Public Supply', '1985': 107.16, '1990': 112.78, '1995': 115.02, '2000': 148.28, '2005': 153.75, '2010': 155.74, '2015': 181.58 },
    { county: 'ADA', type: 'Commercial', '1985': 10.25, '1990': 11.10, '1995': 1.25, '2000': 0.00, '2005': 0.00, '2010': 0.00, '2015': 0.00 },
    { county: 'ADA', type: 'Mining', '1985': 0.00, '1990': 0.00, '1995': 1.25, '2000': 2.27, '2005': 2.82, '2010': 1.99, '2015': 2.63 },
    { county: 'ADA', type: 'Livestock', '1985': 0.00, '1990': 0.00, '1995': 0.00, '2000': 3.62, '2005': 5.33, '2010': 3.92, '2015': 5.89 },
    { county: 'ADA', type: 'Domestic', '1985': 22.70, '1990': 23.26, '1995': 36.48, '2000': 28.90, '2005': 16.26, '2010': 6.38, '2015': 21.66 },

    { county: 'CANYON', type: 'Irrigation Agriculture', '1985': 0.00, '1990': 0.00, '1995': 0.00, '2000': 693.56, '2005': 659.78, '2010': 491.78, '2015': 646.86 },
    { county: 'CANYON', type: 'Public Supply', '1985': 107.16, '1990': 112.78, '1995': 115.02, '2000': 148.28, '2005': 153.75, '2010': 155.74, '2015': 181.58 },
    { county: 'CANYON', type: 'Commercial', '1985': 0.00, '1990': 0.00, '1995': 0.00, '2000': 0.00, '2005': 0.00, '2010': 0.00, '2015': 0.00 },
    { county: 'CANYON', type: 'Mining', '1985': 0.00, '1990': 0.00, '1995': 1.25, '2000': 2.27, '2005': 2.82, '2010': 1.99, '2015': 2.63 },
    { county: 'CANYON', type: 'Livestock', '1985': 0.00, '1990': 0.00, '1995': 0.00, '2000': 3.62, '2005': 5.33, '2010': 3.92, '2015': 5.89 },
    { county: 'CANYON', type: 'Domestic', '1985': 22.70, '1990': 23.26, '1995': 36.48, '2000': 28.90, '2005': 16.26, '2010': 6.38, '2015': 21.66 },
];

export default waterUseData;