//--------------------------------------------------
//Rendered Component for logging in and registering account
//--------------------------------------------------

import React, { Fragment, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import env from "react-dotenv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Select } from 'antd';

import loginBg from '../../Images/login-bg.svg';


const Login = ({ Login, Register, session, isSignUp }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 930); // State to track window width
    const { action } = useParams();

    const login = () => {
        const { useCaptcha } = session
        session.useCaptcha ? Login({ email, password, useCaptcha, captchaToken }) : Login({ email, password })
    }

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const register = () => {
        Register({ name, email, password })
    }

    useEffect(() => {
        document.body.classList.add('login');

        return () => {
            document.body.classList.remove('login');
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 930);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobileView]);

    return (
        <Fragment>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>


            <div className='login-page-container'>
                <img className='login-bg' src={loginBg}></img>
                <div className='login-svg'>
                    <Container fluid className='main-container loginPage p-3 mt-4'>

                        <div className='container flex-container loginBook p-0 m-0'>
                            {!isMobileView ?
                                <>
                                    <div className='leftPage flex-fill text-start'>
                                        {action == 'Login' ?
                                            <Link to="/">
                                                <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                            </Link>
                                            :
                                            <Fragment>
                                                {action == 'Premium' ?
                                                    <Fragment>
                                                        <Link to="/login/SignUp">
                                                            <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                                        </Link>
                                                        <div className='subscribe-left'>
                                                            <br></br>
                                                            <label>Subscribe to premium</label>
                                                            <h2>$20.00</h2> <label>Per Month</label>
                                                            <br></br>
                                                            <p>Premium Subscription&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;$20.00</p>
                                                            <hr></hr>
                                                            <p>Subtotal&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;$20.00</p>
                                                            <label>Tax&emsp;&emsp;&emsp;&emsp;Enter Address to Calculate</label>
                                                            <hr></hr>
                                                            <p>Total due today&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;$20.00</p>
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <Link to="/login/Login">
                                                            <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                                        </Link>
                                                        <div className='sign-up-left'>
                                                            <h1>Welcome,</h1>
                                                            <h2>Sign Up</h2>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </div>
                                    <div className="boxLine"></div>

                                    <div className='rightPage flex-fill align-self-center'>
                                        {action == 'Login' ?
                                            <Fragment>
                                                <div className='username-password'>
                                                    <h1>Welcome,</h1>
                                                    <h2>Log In or Sign Up</h2>
                                                    <br></br>
                                                    <Input className='username-password-input' placeholder="Email or Phone Number" />
                                                    <br></br><br></br>
                                                    <Input.Password
                                                        className='username-password-input'
                                                        placeholder="Password"
                                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                    />
                                                    <br></br>
                                                    <span class="material-symbols-outlined">info</span>
                                                    <label>Forgot Password</label>
                                                    <br></br><br></br>
                                                    <input type="checkbox" className='remember-me' name="remember-me" value="Remember Me"></input>
                                                    <label for="remember-me">Remember Me</label>
                                                    <div id='login-buttons'>
                                                        <Link to="/">
                                                            <button className="loginButton" onClick={() => login()}>Login</button>
                                                        </Link>
                                                        <br></br>
                                                        <Link to="/login/SignUp">
                                                            <button className="loginButton">New? Sign Up</button>
                                                        </Link>
                                                    </div>

                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                {action == 'Premium' ?
                                                    <Fragment>
                                                        <div className='subscribe-right'>
                                                            <h3>Payment Method</h3>
                                                            <label>Card Information</label>
                                                            <Input.Password className='username-password-input' placeholder="1234 1234 1234 1234" />
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <Input className='username-password-input' placeholder="MM / YY" />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <Input className='username-password-input' placeholder="CVC" />
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <label>Cardholder Name</label>
                                                            <Input className='username-password-input' placeholder="Full name on card" />
                                                            <br></br>
                                                            <label>Billing Address</label>
                                                            <Select placeholder="United States" />
                                                            <Input className='username-password-input' placeholder="Address" />
                                                            <br></br>
                                                            <input type="checkbox" className='remember-me' name="remember-me" value="Remember Me"></input>
                                                            <br></br>
                                                            <button className="signUpButton">Subscribe & Sign Up</button>
                                                        </div>

                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <div className='sign-up-section'>
                                                            <h4>Contact Information</h4>
                                                            <Input className='username-password-input' placeholder="First and Last Name" />
                                                            <br></br><br></br>
                                                            <Input className='username-password-input' placeholder="Email" />
                                                            <br></br><br></br>
                                                            <Input className='username-password-input' placeholder="Phone Number" />
                                                            <br></br><br></br>

                                                            <h4>Account Information</h4>
                                                            <Input.Password
                                                                className='username-password-input'
                                                                placeholder="Password"
                                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                            />
                                                            <br></br><br></br>
                                                            <Input.Password
                                                                className='username-password-input'
                                                                placeholder="Re-enter Password"
                                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                            />
                                                            <div className='row'>
                                                                <div className='col-12 password-info'>
                                                                    <span class="material-symbols-outlined">info</span>
                                                                    <label>Passwords must be at least 6 characters.</label>
                                                                </div>
                                                            </div>

                                                            <h4>Additional</h4>
                                                            <div id='become-premium'>
                                                                <label className='premium-label'>Become a</label>
                                                                <Link to='/login/Premium'>
                                                                    <button className="premiumButton" >Premium Member</button>
                                                                </Link>
                                                            </div>

                                                            <Link to="/">
                                                                <button className="signUpButton" onClick={() => register()}>Sign Up</button>
                                                            </Link>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }

                                    </div>
                                </>
                                :
                                <>

                                    <div className='rightPage flex-fill align-self-center'>
                                        {action == 'Login' ?
                                            <Fragment>
                                                <Link to="/">
                                                    <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                                </Link>
                                                <div className='username-password'>
                                                    <h1>Welcome,</h1>
                                                    <h2>Log In or Sign Up</h2>
                                                    <br></br>
                                                    <Input className='username-password-input' placeholder="Email or Phone Number" />
                                                    <br></br><br></br>
                                                    <Input.Password
                                                        className='username-password-input'
                                                        placeholder="Password"
                                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                    />
                                                    <br></br>
                                                    <span class="material-symbols-outlined">info</span>
                                                    <label>Forgot Password</label>
                                                    <br></br><br></br>
                                                    <input type="checkbox" className='remember-me' name="remember-me" value="Remember Me"></input>
                                                    <label for="remember-me">Remember Me</label>
                                                    <div id='login-buttons'>
                                                        <Link to="/">
                                                            <button className="loginButton" onClick={() => login()}>Login</button>
                                                        </Link>
                                                        <br></br>
                                                        <Link to="/login/SignUp">
                                                            <button className="loginButton">New? Sign Up</button>
                                                        </Link>
                                                    </div>

                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                {action == 'Premium' ?
                                                    <Fragment>
                                                        <Link to="/login/SignUp">
                                                            <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                                        </Link>
                                                        <div className='subscribe-right'>
                                                            <h3>Payment Method</h3>
                                                            <label>Card Information</label>
                                                            <Input.Password className='username-password-input' placeholder="1234 1234 1234 1234" />
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <Input className='username-password-input' placeholder="MM / YY" />
                                                                </div>
                                                                <div className='col-6'>
                                                                    <Input className='username-password-input' placeholder="CVC" />
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <label>Cardholder Name</label>
                                                            <Input className='username-password-input' placeholder="Full name on card" />
                                                            <br></br>
                                                            <label>Billing Address</label>
                                                            <Select placeholder="United States" />
                                                            <Input className='username-password-input' placeholder="Address" />
                                                            <br></br>
                                                            <input type="checkbox" className='remember-me' name="remember-me" value="Remember Me"></input>
                                                            <br></br>
                                                            <button className="signUpButton">Subscribe & Sign Up</button>
                                                        </div>

                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <Link to="/login/Login">
                                                            <button id="returnButton"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                                        </Link>
                                                        <div className='sign-up-section'>
                                                            <h4>Contact Information</h4>
                                                            <Input className='username-password-input' placeholder="First and Last Name" />
                                                            <br></br><br></br>
                                                            <Input className='username-password-input' placeholder="Email" />
                                                            <br></br><br></br>
                                                            <Input className='username-password-input' placeholder="Phone Number" />
                                                            <br></br><br></br>

                                                            <h4>Account Information</h4>
                                                            <Input.Password
                                                                className='username-password-input'
                                                                placeholder="Password"
                                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                            />
                                                            <br></br><br></br>
                                                            <Input.Password
                                                                className='username-password-input'
                                                                placeholder="Re-enter Password"
                                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                            />
                                                            <div className='row'>
                                                                <div className='col-9 password-info'>
                                                                    <span class="material-symbols-outlined">info</span>
                                                                    <label>Passwords must be at least 6 characters.</label>
                                                                </div>
                                                            </div>
                                                            <br></br>

                                                            <h4>Additional</h4>
                                                            <div id='become-premium'>
                                                                <label className='premium-label'>Become a</label>
                                                                <Link to='/login/Premium'>
                                                                    <button className="premiumButton" >Premium Member</button>
                                                                </Link>
                                                            </div>

                                                            <Link to="/">
                                                                <button className="signUpButton" onClick={() => register()}>Sign Up</button>
                                                            </Link>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }

                                    </div>
                                </>
                            }
                        </div>
                    </Container>
                    <br></br><br></br>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    Login: userActions.Login,
    Register: userActions.Register,
};

const connection = connect(mapState, actionCreators)(Login);
export { connection as Login };