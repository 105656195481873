//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { userActions } from "../../Actions";
import { LoginModal } from '../GeneralComponents/';
import { connect } from 'react-redux';
import { Form, Container, Row, Col } from 'react-bootstrap';

import buttonSvg1 from '../../Images/button-svg1.svg';
import buttonSvg2 from '../../Images/button-svg2.svg';
import buttonSvg3 from '../../Images/button-svg3.svg';
import buttonSvg4 from '../../Images/button-svg4.svg';
import buttonSvg5 from '../../Images/button-svg5.svg';
import wavesHr from '../../Images/waves-hr.svg';
import landingPageBg from '../../Images/NCP6.JPG';
import landingPageImg from '../../Images/NCP3.JPG';
import landingPageImg2 from '../../Images/NCP7.JPG';
import searchButton from '../../Images/search-button2.svg';
import searchButton2 from '../../Images/search-button3.svg';
import searchDelete from '../../Images/search-delete.svg';

const Home = ({ }) => {
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [hasText, setHasText] = useState(false);
    const categories = ['Maps', 'Articles', 'Curriculum', 'Interactive', 'User Tools'];
    const navigate = useNavigate();
    
    const hasModalBeenShown = localStorage.getItem('modalShown');

      useEffect(() => {
        const handleScroll = () => {
          const scrollThreshold = 500; 
          if (window.scrollY > scrollThreshold && !hasModalBeenShown) {
            setShowModal(true);
            localStorage.setItem('modalShown', 'true');
            window.removeEventListener('scroll', handleScroll);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    const handleClose = () => {
        setShowModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
            navigate(`/search?q=${searchQuery}`);
    };

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        setHasText(e.target.value.length > 0);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setHasText(false);
    };

    const handleCategorySearch = (category) => {
        setSelectedCategory(category);
        navigate(`/search?category=${category}`);
    };

    return (
        <Fragment>  
        <Container fluid className='menu'>

            <LoginModal show={showModal} onHide={handleClose}/>
            <br/>
            <img className='menu-bg-img' src={landingPageBg}></img>
            <Row className='menu-header'>
                <h1>Treasure Valley Water Hub</h1>
                <p className='menu-sub-header'>Your Access to <span className='gradient-word'>More</span></p>
            </Row>

            <Container fluid className='menu-buttons'>
                <Row className='justify-content-center menu-buttons-top-row'>
                    <Col sm={3}>
                        <Link to="/groundwater-map">
                            <button><img src={buttonSvg1}></img><br/>Groundwater Levels Map</button>
                        </Link>
                    </Col>
                    <Col sm={3}>
                        <Link to="/voices">
                            <button><img src={buttonSvg3}></img><br/>Voices of the Treasure Valley</button>
                        </Link>
                    </Col>
                    
                    <Col sm={3}>
                        <Link to="water-org-directory">
                            <button><img src={buttonSvg4}></img><br/>Water Organization's Directory</button>
                        </Link>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col sm={3}>
                        <Link to="/water-atlas" target='_blank'>
                            <button><img src={buttonSvg2}></img><br/>Learn About the Boise River Basin</button>
                        </Link>
                    </Col>

                    <Col sm={3}>
                        <Link to="/irrigation-districts">
                            <button><img src={buttonSvg5}></img><br/>Find Your Irrigation District</button>
                        </Link>
                    </Col>
                </Row>
            </Container>

            <img className='wave-hr' src={wavesHr}></img>

            <Container fluid  className='search-section'>
                <Row className='justify-content-center'>
                    <Col sm={5}>
                        <img className='landing-page-img' src={landingPageImg}></img>
                    </Col>
                    <Col md={6} className='search-area'>
                        <h1>Find What You Need</h1>
                        <div className='search-box'>
                            <div className='behind-search'></div>
                            <img src={searchDelete} className={`search-delete ${hasText ? 'search-delete-visible' : ''}`} onClick={handleClearSearch} />
                                <Form inline onSubmit={handleSubmit} className='p-1'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Water Hub"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        className={`mr-sm-2 navbar-search search-bar ${hasText ? 'padded-input' : ''}`}
                                    />
                                </Form>

                            <img className='search-button' src={hasText ? searchButton2 : searchButton} onClick={handleSubmit}
                            style={{ transform: hasText ? 'translateX(30px)' : 'translateX(0)',
                                width: hasText ? '15%' : '5.5%' }}></img>
                        </div>

                        <div className='topic-box'>
                            {categories.map(category => (
                                <button key={category} onClick={() => handleCategorySearch(category)}>{category}</button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='menu-about-section'>
                <Row className='justify-content-center'>
                    <Col sm={6} className='menu-info'>
                        <h3>What is the Treasure Valley</h3>
                        <h3>Water Hub?</h3>
                        <h4>A collaborative effort to share Idaho's water data</h4>
                        <p>The Treasure Valley Water Hub was developed by Immerse to be a one-stop resource which integrates existing water data visualizations and education assets into one website. This Hub serves as a bridge between interested community members, water managers and policy makers alike to create shared understanding of our water resources. We are excited to curate engaging information and community insights and empower people to explore content about the Treasure Valley’s water as we work to support a future of successful policy and management.</p>
                    </Col>
                    <Col sm={4} className='menu-info-img'>
                        <img src={landingPageImg2}></img>
                    </Col>
                </Row>
            </Container>
        </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Home);
export { connection as Home };
