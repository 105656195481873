//--------------------------------------------------
//Manages websocket connection to server
//--------------------------------------------------
import env from "react-dotenv";
import { socketClientListeners } from '../Actions/socketActions'; 

const API = env?.SOCKET_API || "http://localhost:5000/socket-nsp"; //Location of the server

const io = require('socket.io-client'); 

let socket = null;

export const connectSocket = () => {
  console.log("Connecting to socket.");
  if (!socket) {
    socket = io(API, {
      "force new connection": true,
      "reconnectionAttempts": "Infinity",
      "timeout": 10000,
      "transports": ["websocket"]
    });
  }
};

export const disconnectSocket = () => {
  console.log("Disconnecting from socket.");
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => {
  if(socket) {
    return socket;
  }
};




