//--------------------------------------------------
//Template page to copy/paste for new pages
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import '../../css/data-visualizations.css';

const Tooltip = ({ interactionData }) => {
  
  //Get tooltip width for dynamic positioning
  const tooltipRef = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState(100);
  const [tooltipHeight, setTooltipHeight] = useState(50);
  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipWidth(tooltipRef.current.offsetWidth);
      setTooltipHeight(tooltipRef.current.offsetHeight);
      //console.log("Tooltip width:", tooltipRef.current.offsetWidth);
    }
  }, [interactionData]);

  // If interactionData is not provided, return null (don't render anything)
  if (!interactionData) {
    return null;
  }
  
  //getting the properties from interactionData
  const { xPos, yPos, name, value, color, title, value2, nameLabel, value1Label, value2Label, width, height } = interactionData;
  //console.log("tooltip interaction data: " + interactionData);
  //console.log("tooltip xpos: " + xPos + " ypos: " + yPos + " name: " + name + " value: " + value + " color: " + color + "title: " + title);

  // Determine whether to place the tooltip to the left or right of the cursor
  let leftPosition = xPos;
  if(width){
    if(xPos > (width/2) + 70){
      leftPosition = xPos - (tooltipWidth + 5);
    } else if (xPos < (width/2)){
      leftPosition = xPos + 10;
    }
  } 

  let topPosition = yPos;
  console.log("vvvv yPos: ", yPos);
  if(height){
    if(yPos < (height/2) + 25){
      topPosition = yPos + (tooltipHeight/2) - 10;
      console.log("vvvv new less than topPosition: ", topPosition);
    } else {
      topPosition = yPos + 35;
      console.log("vvvv new greater than topPosition: ", topPosition);
    }
  }

  //if(xPos )
  //const leftPosition = xPos + tooltipWidth + threshold > screenWidth ? xPos - tooltipWidth : xPos;
  //console.log("left pos: ", leftPosition);

  // Returning tooltip component with dynamic positioning and content based on interactionData
  return (
    <div
      className="tooltip"
      ref={tooltipRef}
      style={{
        left: leftPosition, // Dynamically setting left position
        top: topPosition, // Dynamically setting top position
      }}
    >
      {/* Rendering {title} or {name} if {title} is not provided */}
      <b className="title">{title ? title : name} </b>

      <div className="topHalfContainer" style={{ borderColor: color }}>
        <div className="tooltip-row">
          <p><b>{nameLabel ? nameLabel : "Name"}</b> {name}</p> {/* Rendering {nameLabel} or "Name" if {nameLabel} is not provided */}

        </div>
        <div className="tooltip-row">
          <p><b>{value1Label? value1Label : "Value"}</b> {value}</p> {/* Rendering {value1Label} or "Value" if {value1Label} is not provided */}

        </div>
        {/* Rendering value2 if provided */}
        {value2 ?
          <div className="tooltip-row">
            <span>{value2Label? value2Label : "Second Value"}</span> {/* Rendering {value2Label} or "Second Value" if {value2Label} is not provided */}
            <p>{value2}</p>
          </div> : <></>}
      </div>

      {/*more space for content if needed later */}
      {/*<div className="separator" />
  
        <div className="tooltip-row">
          <span>
            CO2 emission
            <br />
            (tons per capita)
          </span>
          <b>math</b>
        </div>*/}
    </div>
  );
};


function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Tooltip);
export { connection as Tooltip };