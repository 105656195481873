import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Grid, Pagination, Navigation } from 'swiper/modules';

import NIDISMap from '../../Images/NIDIS_Wildfire_Drought.png';
import NMGroundWaterMap from '../../Images/Groundwater_Map.png';
import NWRiverForecastingMap from '../../Images/NW_River_Forecasting_map.png';
import USGSWaterAtlas from '../../Images/USGS_Water_Atlas.jpg'
import NOAAWaterPrediction from '../../Images/NOAA_Water_Prediction.jpg'
import FakeTool from '../../Images/Fake_tool.jpg'
import filterIcon from '../../Images/filter.svg'
import watershedMap from '../../Images/watershedMap.PNG'
import irrigationDistricts from '../../Images/irrigationDistricts.PNG'

const maps = [
    { name: 'Groundwater Levels', content: 'Explore your local groundwater levels and historical trends.', img: NMGroundWaterMap, path: '/groundwater-map', filter: 'levels', offsite: false },
    { name: 'Irrigation Districts', content: 'Which irrigation district are you in?', img: irrigationDistricts, path: '/irrigation-districts', filter: 'education', offsite: false },
    { name: 'Watershed Map', content: 'Explore story maps from across the basin.', img: watershedMap, path: '/watershed', filter: 'education', offsite: false },
    { name: 'Northwest River Forecasting', content: "Explore the NOAA's Northwest River Forecast Center.", img: NWRiverForecastingMap, path: 'https://www.nwrfc.noaa.gov/rfc/', filter: 'predictions', offsite: true },
    { name: 'Drought & Wildfire', content: "Explore your the NIDIS' information on wildfires and droughts.", img: NIDISMap, path: 'https://www.drought.gov/sectors/wildfire-management/interactive-map', filter: 'risks', offsite: true },
    { name: 'USGS Water Atlas', content: "Explore the US Geological Survey's National Modeled Water Atlas for nationally consistent modelled water quanity, quality, and use data.", img: USGSWaterAtlas, path: 'https://www.usgs.gov/nwc#overview', filter: 'predictions', offsite: true },
    { name: 'NOAA Water Prediction', content: "Explore the National Oceanic and Atmospheric Administration's National Water Prediction Service map.", img: NOAAWaterPrediction, path: 'https://water.noaa.gov/#@=-96.401081,38.1465724,2&b=topographic&g=obsFcst,1!1!1!1!1!1!1!1!1!1!1!1!1!1!1!0!0!0!0!0,0.5,1!1!1!1!0,0,0&ab=0,0,#D94B4A,1,1,1,#cccccc,1,0,0,#B243B1,1,0,0,#98E09A,1&a=hydrologic,0.35&s=0,0,0.9,0.9&n=false,#72afe9,0.9,0,0.9,0,0.9&p=false,0.75,0,7,0,1,2024,6,26,0&d=0,0,1,1,1,1,1,1,#006EFF,1,#006EFF,1,#006EFF&q=', filter: 'predictons', offsite: true },
    { name: 'Land and Water Development Tool', content: 'In development', img: FakeTool, path: '/0', filter: 'tools', offsite: false },
];

const MapCollection = ({ }) => {
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        // Update Swiper slides based on filter
        if (filter === 'all') {
            document.querySelectorAll('.swiper-slide').forEach(slide => {
                slide.classList.remove('non-swiper-slide');
                slide.style.display = '';
            });
        } else {
            document.querySelectorAll('.swiper-slide').forEach(slide => {
                if (slide.getAttribute('data-filter') === filter) {
                    slide.classList.remove('non-swiper-slide');
                    slide.style.display = '';
                } else {
                    slide.classList.add('non-swiper-slide');
                    slide.style.display = 'none';
                }
            });
        }

    }, [filter]);

    const getButtonStyle = (currentFilter) => {
        return filter === currentFilter ? { backgroundColor: '#AFCFFF', color: '#1D192B', border: '1px solid #AFCFFF', fontWeight: '600' } : {};
    };

    return (
        <Fragment>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <div className='map-collection'>

                <div className='map-collection-container'>

                    <div className='collection-title-left-align'>
                        <div className='collection-title'>
                            <h1>Data & Maps</h1>
                            <h3>Browse our map selection</h3>
                        </div>
                    </div>

                    <div className='filters-container'>
                        <div className='filters-bg'>
                            <div className='filters'>
                                <img src={filterIcon}></img>
                                <button style={getButtonStyle('all')} onClick={() => setFilter('all')}>All</button>
                                <button style={getButtonStyle('quality')} onClick={() => setFilter('quality')}>Quality</button>
                                <button style={getButtonStyle('levels')} onClick={() => setFilter('levels')}>Levels</button>
                                <button style={getButtonStyle('predictions')} onClick={() => setFilter('predictions')}>Predictions</button>
                                <button style={getButtonStyle('risks')} onClick={() => setFilter('risks')}>Risks</button>
                                <button style={getButtonStyle('atlas')} onClick={() => setFilter('atlas')}>Atlas</button>
                                <button style={getButtonStyle('tools')} onClick={() => setFilter('tools')}>Tools</button>
                                <button style={getButtonStyle('education')} onClick={() => setFilter('education')}>Education</button>
                            </div>
                        </div>
                    </div>

                    {/* map links */}
                    <div className='collection-bg'>
                        <div className='map-link-container'>
                            <Row>
                                <Col className='side-scroll-btn-container'>
                                    <button className='side-scroll-btn side-scroll-prev'>
                                        <svg width="25" height="51" viewBox="0 0 15 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 2L2 20.5L13 39" stroke="#112644" stroke-width="3" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </Col>
                                <Col md={10}>
                                    <Swiper
                                        navigation={{ nextEl: '.side-scroll-next', prevEl: '.side-scroll-prev' }}

                                        setWrapperSize={false}
                                        grid={{ rows: 2, fill: "column", }}

                                        pagination={{ clickable: true }}
                                        modules={[Grid, Pagination, Navigation]}
                                        className="mapSwiper"
                                        breakpoints={{
                                            450: {
                                                slidesPerView: 2,
                                                spaceBetween: 50,
                                            },
                                            630: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                            }
                                        }
                                        }
                                    >
                                        {maps.map((map, index) => (
                                            <SwiperSlide data-filter={map.filter} key={index}>
                                                <Link to={map.path} target={map.offsite ? '_blank' : ''}>
                                                    <Col className='map-link'>
                                                        <Row >
                                                            <h3>{map.name}</h3>
                                                            <Col className='icon-container'>
                                                                <img src={map.img} className='collection-icon' />
                                                            </Col>
                                                            <Col className='map-link-content'>

                                                                <p>{map.content}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Link>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Col>
                                <Col className='side-scroll-btn-container'>
                                    <button className='side-scroll-btn side-scroll-next'><svg width="25" height="51" viewBox="0 0 15 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 2L13 20.5L2 39" stroke="#112644" stroke-width="3" stroke-linecap="round" />
                                    </svg>
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>
                <br /><br /><br /><br />
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(MapCollection);
export { connection as MapCollection };