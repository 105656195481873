//--------------------------------------------------
//Rendered Component for testing features
//--------------------------------------------------
import React, { Fragment, useEffect, useState } from 'react';
import { userActions, socketActions } from "../../Actions";
import { connect, useDispatch } from 'react-redux';
import DragDrop from '../GeneralComponents/dragdrop'
import { getSocket } from "../../Socket/socket";
import { socketConstants } from "../../Redux/constants";

const DefaultPage = ({ DefaultAction, session, Upload }) => {
    const [list, setList] = useState([
        {item: "Alert System"},
        {item: "History and Routing"},
        {item: "Admin Restrictive Pages"},
        {item: "User authentication"},
    ]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [socketText, setsocketText] = useState("");

    useEffect(() => {
        if(session.token) DefaultAction(session.token);
    }, []); 

    const onListReorder = (result) => {
        setList(result)
    }

    const uploadFiles = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            Upload(formData)
        }
    }

    //Use socket event listener with redux 
    const dispatch = useDispatch();
    const socket = getSocket();
    useEffect(() => {
        socket?.on('roomMessage', () => {
            dispatch({type: socketConstants.TestAction, info: "Client Message"});
        });
        return () => {
          socket?.off('roomMessage');
        };
    }, [socket, dispatch]);

    return (
        <Fragment>
            <div className='main-container defaultpage'>

                <div className='border-container'>
                    <h1>Default Page</h1>
                </div>

                <div className='container'>
                    <div>
                        <span>Clearance:</span> {session.clearance ? <p>{session.clearance}</p> : <Fragment/>}
                    </div>
                    <button onClick={() => userActions.reroute("")}>First Page</button>
                </div>

                <div className='container'>
                    <input className='center' type='text' value={socketText} onChange={(e) => setsocketText(e.target.value)}></input>

                    <button onClick={() => socketActions.createConnection()}>Connect Socket</button>
                    <button onClick={() => socketActions.removeConnection()}>Disconnect Socket</button>

                    <button onClick={() => socketActions.createRoom(socketText)}>Create Room</button>
                    <button onClick={() => socketActions.joinRoom(socketText)}>Join Room</button>
                    <button onClick={() => socketActions.leaveRoom(socketText)}>Leave Room</button>
                    <button onClick={() => socketActions.sendMessage(socketText, "Socket Message To Room")}>Send Room Message</button>
                </div>

                <div className='container'>
                    <input type="file" className='center' onChange={(e) => setSelectedFile(e.target.files[0])} />
                    <button onClick={(e) => uploadFiles()}>Upload</button>
                </div>
                
                <div className='container'>
                    <DragDrop array={list} onListReorder={onListReorder} prop={"item"}></DragDrop>
                </div>

            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    DefaultAction: userActions.DefaultAction,
    Upload: userActions.Upload,
};

const connection = connect(mapState, actionCreators)(DefaultPage);
export { connection as DefaultPage };