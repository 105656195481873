let irrigationDISTRICTContacts = [
    {
      "OrganizationName": "A & B IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 675",
      "City": "RUPERT",
      "State": "ID",
      "ZIP": "83350-0675",
      "BusinessPhone": "208-436-3152"
    },
    {
      "OrganizationName": "AMERICAN FALLS RESERVOIR DISTRICT #2 & BIG WOOD CANAL COMPANY",
      "StreetAddress": "409 N APPLE ST",
      "City": "SHOSHONE",
      "State": "ID",
      "ZIP": "83352-5415",
      "BusinessPhone": "208-886-2331"
    },
    {
      "OrganizationName": "AMERICAN FALLS RESERVOIR DISTRICT",
      "StreetAddress": "1035 N LINCOLN AVE",
      "City": "JEROME",
      "State": "ID",
      "ZIP": "83338",
      "BusinessPhone": "208-324-8835"
    },
    {
      "OrganizationName": "AVONDALE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 81",
      "City": "HAYDEN LAKE",
      "State": "ID",
      "ZIP": "83835",
      "BusinessPhone": "208-772-5657"
    },
    {
      "OrganizationName": "BIG BEND IRRIGATION DISTRICT",
      "StreetAddress": "HC 33 BOX 1026",
      "City": "BOISE",
      "State": "ID",
      "ZIP": "83706",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "BIG LOST RIVER IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 205",
      "City": "MACKAY",
      "State": "ID",
      "ZIP": "83251",
      "BusinessPhone": "208-588-2231"
    },
    {
      "OrganizationName": "BLACK CANYON IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 226",
      "City": "NOTUS",
      "State": "ID",
      "ZIP": "83656",
      "BusinessPhone": "208-459-4141"
    },
    {
      "OrganizationName": "BOISE-KUNA IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 330",
      "City": "KUNA",
      "State": "ID",
      "ZIP": "83634",
      "BusinessPhone": "208-922-5608"
    },
    {
      "OrganizationName": "BURLEY IRRIGATION DISTRICT",
      "StreetAddress": "246 E 100 S",
      "City": "BURLEY",
      "State": "ID",
      "ZIP": "83318",
      "BusinessPhone": "208-678-2511"
    },
    {
      "OrganizationName": "CANYON HILL IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 308",
      "City": "CALDWELL",
      "State": "ID",
      "ZIP": "83606-0308",
      "BusinessPhone": "208-459-0876"
    },
    {
      "OrganizationName": "CAPITOL VIEW IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 208",
      "City": "EAGLE",
      "State": "ID",
      "ZIP": "83616",
      "BusinessPhone": "208-939-7292"
    },
    {
      "OrganizationName": "CASSIA CREEK IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 396",
      "City": "RUPERT",
      "State": "ID",
      "ZIP": "83350",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "CENTER IRRIGATION DISTRICT",
      "StreetAddress": "12252 HWY 55",
      "City": "CASCADE",
      "State": "ID",
      "ZIP": "83611",
      "BusinessPhone": "208-382-4184"
    },
    {
      "OrganizationName": "COVE IRRIGATION DISTRICT",
      "StreetAddress": "Consolidated with Triangle Irrigation District Nov. 22, 2016. See Triangle Irrigation District for contact Info",
      "City": "",
      "State": "",
      "ZIP": "",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "CUB RIVER IRRIGATION",
      "StreetAddress": "C/O MERVIN BODILY PO BOX 215",
      "City": "LEWISTON",
      "State": "UT",
      "ZIP": "84320",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "DALTON GARDENS IRRIGATION DISTRICT",
      "StreetAddress": "6360 N 4TH ST",
      "City": "DALTON GARDENS",
      "State": "ID",
      "ZIP": "83815",
      "BusinessPhone": "208-772-5639"
    },
    {
      "OrganizationName": "EAST GREENACRES IRRIGATION DISTRICT",
      "StreetAddress": "RT 1 BOX 116",
      "City": "POST FALLS",
      "State": "ID",
      "ZIP": "83854",
      "BusinessPhone": "208-687-2775"
    },
    {
      "OrganizationName": "EMMETT IRRIGATION DISTRICT",
      "StreetAddress": "222 E PARK ST",
      "City": "EMMETT",
      "State": "ID",
      "ZIP": "83617",
      "BusinessPhone": "208-365-4582"
    },
    {
      "OrganizationName": "ENTERPRISE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 29",
      "City": "TETON",
      "State": "ID",
      "ZIP": "83451",
      "BusinessPhone": "208-458-4560"
    },
    {
      "OrganizationName": "FALLS IRRIGATION DISTRICT",
      "StreetAddress": "310 VALDEZ ST",
      "City": "AMERICAN FALLS",
      "State": "ID",
      "ZIP": "83211",
      "BusinessPhone": "208-226-5227"
    },
    {
      "OrganizationName": "FISH CREEK IRRIGATION DISTRICT",
      "StreetAddress": "80 LAIDLAW PARK RD",
      "City": "CAREY",
      "State": "ID",
      "ZIP": "83320",
      "BusinessPhone": "208-539-4122"
    },
    {
      "OrganizationName": "FREMONT-MADISON IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 15",
      "City": "ST ANTHONY",
      "State": "ID",
      "ZIP": "83445",
      "BusinessPhone": "208-624-3381"
    },
    {
      "OrganizationName": "GEM IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 67",
      "City": "HOMEDALE",
      "State": "ID",
      "ZIP": "83628",
      "BusinessPhone": "208-337-3760"
    },
    {
      "OrganizationName": "GOLDEN GATE IRRIGATION DISTRICT",
      "StreetAddress": "5700 E FRANKLIN RD STE 200",
      "City": "NAMPA",
      "State": "ID",
      "ZIP": "83687",
      "BusinessPhone": "208-440-7974"
    },
    {
      "OrganizationName": "GOOSE CREEK IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 207",
      "City": "OAKLEY",
      "State": "ID",
      "ZIP": "83346",
      "BusinessPhone": "208-878-8382"
    },
    {
      "OrganizationName": "GRAND VIEW IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 9",
      "City": "GRAND VIEW",
      "State": "ID",
      "ZIP": "83624",
      "BusinessPhone": "208-834-2350"
    },
    {
      "OrganizationName": "HAYDEN LAKE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 162",
      "City": "HAYDEN",
      "State": "ID",
      "ZIP": "83835",
      "BusinessPhone": "208-772-2612"
    },
    {
      "OrganizationName": "HILLSDALE IRRIGATION DISTRICT",
      "StreetAddress": "840 VALLEY RD",
      "City": "HAZELTON",
      "State": "ID",
      "ZIP": "83335",
      "BusinessPhone": "208-825-5807"
    },
    {
      "OrganizationName": "IDAHO IRRIGATION DISTRICT",
      "StreetAddress": "496 E 14TH",
      "City": "IDAHO FALLS",
      "State": "ID",
      "ZIP": "83404",
      "BusinessPhone": "208-522-2356"
    },
    {
      "OrganizationName": "INDIAN COVE IRRIGATION DISTRICT",
      "StreetAddress": "27186 JOE BLACK RD",
      "City": "HAMMETT",
      "State": "ID",
      "ZIP": "83627",
      "BusinessPhone": "208-366-2158"
    },
    {
      "OrganizationName": "KING HILL IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 428",
      "City": "KING HILL",
      "State": "ID",
      "ZIP": "83633",
      "BusinessPhone": "208-336-2243"
    },
    {
      "OrganizationName": "LAKE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 644",
      "City": "LAKE FORK",
      "State": "ID",
      "ZIP": "83635",
      "BusinessPhone": "208-634-9672"
    },
    {
      "OrganizationName": "LEMHI IRRIGATION DISTRICT",
      "StreetAddress": "206 WITHINGTON CREEK RD",
      "City": "SALMON",
      "State": "ID",
      "ZIP": "83467",
      "BusinessPhone": "208-756-3032"
    },
    {
      "OrganizationName": "LEWISTON ORCHARDS IRRIGATION DISTRICT",
      "StreetAddress": "1520 POWERS AVE",
      "City": "LEWISTON",
      "State": "ID",
      "ZIP": "83501",
      "BusinessPhone": "208-746-8235"
    },
    {
      "OrganizationName": "LITTLE WILLOW IRRIGATION DISTRICT",
      "StreetAddress": "5700 LITTLE WILLOW RD",
      "City": "PAYETTE",
      "State": "ID",
      "ZIP": "83661",
      "BusinessPhone": "208-642-4918"
    },
    {
      "OrganizationName": "LITTLE WOOD RIVER IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 355",
      "City": "CAREY",
      "State": "ID",
      "ZIP": "83320",
      "BusinessPhone": "208-823-4014"
    },
    {
      "OrganizationName": "MILNER LOW LIFT IRRIGATION DISTRICT",
      "StreetAddress": "5294 E 3610 N",
      "City": "MURTAUGH",
      "State": "ID",
      "ZIP": "83344",
      "BusinessPhone": "208-432-5560"
    },
    {
      "OrganizationName": "MINIDOKA IRRIGATION DISTRICT",
      "StreetAddress": "98 W 50 S",
      "City": "RUPERT",
      "State": "ID",
      "ZIP": "83350",
      "BusinessPhone": "208-436-3188"
    },
    {
      "OrganizationName": "MONROE CREEK IRRIGATION DISTRICT",
      "StreetAddress": "1116 HWY 95",
      "City": "WEISER",
      "State": "ID",
      "ZIP": "83672",
      "BusinessPhone": "208-549-1144"
    },
    {
      "OrganizationName": "MOUNTAIN HOME IRRIGATION DISTRICT",
      "StreetAddress": "140 S 3RD E",
      "City": "MOUNTAIN HOME",
      "State": "ID",
      "ZIP": "83647",
      "BusinessPhone": "208-587-4867"
    },
    {
      "OrganizationName": "MURPHY IRRIGATION DISTRICT",
      "StreetAddress": "15103 MURPHY FLAT RD",
      "City": "MURPHY",
      "State": "ID",
      "ZIP": "83650-5095",
      "BusinessPhone": "208-834-2738"
    },
    {
      "OrganizationName": "NAMPA & MERIDIAN IRRIGATION DISTRICT",
      "StreetAddress": "1503 1ST ST SOUTH",
      "City": "NAMPA",
      "State": "ID",
      "ZIP": "83651",
      "BusinessPhone": "208-466-7861"
    },
    {
      "OrganizationName": "NEW SWEDEN IRRIGATION DISTRICT",
      "StreetAddress": "2350 W 1700 S",
      "City": "IDAHO FALLS",
      "State": "ID",
      "ZIP": "83402",
      "BusinessPhone": "208-523-0175"
    },
    {
      "OrganizationName": "NEW YORK IRRIGATION DISTRICT",
      "StreetAddress": "6616 OVERLAND RD",
      "City": "BOISE",
      "State": "ID",
      "ZIP": "83709",
      "BusinessPhone": "208-378-1023"
    },
    {
      "OrganizationName": "OPALINE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 331",
      "City": "MARSING",
      "State": "ID",
      "ZIP": "83639",
      "BusinessPhone": "208-896-4217"
    },
    {
      "OrganizationName": "PARMA IRRIGATION DISTRICT",
      "StreetAddress": "RT 1",
      "City": "PARMA",
      "State": "ID",
      "ZIP": "83660",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "PIONEER IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 426",
      "City": "CALDWELL",
      "State": "ID",
      "ZIP": "83605",
      "BusinessPhone": "208-459-3617"
    },
    {
      "OrganizationName": "POPLAR IRRIGATION DISTRICT",
      "StreetAddress": "16726 E RIRIE HWY",
      "City": "RIRIE",
      "State": "ID",
      "ZIP": "83443",
      "BusinessPhone": "208-538-7372"
    },
    {
      "OrganizationName": "PROGRESSIVE IRRIGATION DISTRICT",
      "StreetAddress": "2585 N AMMON RD",
      "City": "IDAHO FALLS",
      "State": "ID",
      "ZIP": "83401-1949",
      "BusinessPhone": "208-522-3102"
    },
    {
      "OrganizationName": "REYNOLDS IRRIGATION DISTRICT",
      "StreetAddress": "13528 UPPER REYNOLDS CREEK RD",
      "City": "MELBA",
      "State": "ID",
      "ZIP": "83641-4173",
      "BusinessPhone": "208-495-2810"
    },
    {
      "OrganizationName": "RIVERSIDE IRRIGATION DISTRICT LTD",
      "StreetAddress": "25502 PET LN",
      "City": "PARMA",
      "State": "ID",
      "ZIP": "83660",
      "BusinessPhone": "208-880-3449"
    },
    {
      "OrganizationName": "ROSEBERRY IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 42",
      "City": "DONNELLY",
      "State": "ID",
      "ZIP": "83615",
      "BusinessPhone": "208-325-8768"
    },
    {
      "OrganizationName": "SETTLERS IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 7571",
      "City": "BOISE",
      "State": "ID",
      "ZIP": "83707",
      "BusinessPhone": "208-344-2471"
    },
    {
      "OrganizationName": "SEVEN SUCKERS IRRIGATION DISTRICT",
      "StreetAddress": "RT 1",
      "City": "EAGLE",
      "State": "ID",
      "ZIP": "83616",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "SNAKE RIVER IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 9",
      "City": "GRAND VIEW",
      "State": "ID",
      "ZIP": "83624",
      "BusinessPhone": "208-834-2358"
    },
    {
      "OrganizationName": "SNAKE RIVER VALLEY IRRIGATION DISTRICT",
      "StreetAddress": "221 S EMERSON",
      "City": "SHELLEY",
      "State": "ID",
      "ZIP": "83274",
      "BusinessPhone": "208-357-3420"
    },
    {
      "OrganizationName": "SOUTHWEST IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 910",
      "City": "BURLEY",
      "State": "ID",
      "ZIP": "83318",
      "BusinessPhone": "208-878-8382"
    },
    {
      "OrganizationName": "SQUAW CREEK IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 15",
      "City": "SWEET",
      "State": "ID",
      "ZIP": "83670",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "STRONGARM IRRIGATION DISTRICT",
      "StreetAddress": "RT 3",
      "City": "PRESTON",
      "State": "ID",
      "ZIP": "83263",
      "BusinessPhone": ""
    },
    {
      "OrganizationName": "SUNNYDELL IRRIGATION DISTRICT",
      "StreetAddress": "9298 S 600 E",
      "City": "REXBURG",
      "State": "ID",
      "ZIP": "83440",
      "BusinessPhone": "208-356-5138"
    },
    {
      "OrganizationName": "TRIANGLE IRRIGATION DISTRICT (partitioned from Wood River Valley Irr. DISTRICT. #45)",
      "StreetAddress": "PO BOX 424",
      "City": "BELLEVUE",
      "State": "ID",
      "ZIP": "83313",
      "BusinessPhone": "208-720-5141"
    },
    {
      "OrganizationName": "TRINITY MOUNTAIN IRRIGATION DISTRICT",
      "StreetAddress": "1015 PRAIRIE RD",
      "City": "PRAIRIE",
      "State": "ID",
      "ZIP": "83647",
      "BusinessPhone": "208-868-3224"
    },
    {
      "OrganizationName": "WEISER COVE IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 829",
      "City": "WEISER",
      "State": "ID",
      "ZIP": "83672",
      "BusinessPhone": "208-549-0611"
    },
    {
      "OrganizationName": "WEISER IRRIGATION DISTRICT",
      "StreetAddress": "PO BOX 831",
      "City": "WEISER",
      "State": "ID",
      "ZIP": "83672",
      "BusinessPhone": "208-573-5024"
    },
    {
      "OrganizationName": "WEST REYNOLDS IRRIGATION DISTRICT",
      "StreetAddress": "HC 79 BOX 94",
      "City": "MELBA",
      "State": "ID",
      "ZIP": "83641",
      "BusinessPhone": "208-495-2228"
    },
    {
      "OrganizationName": "WILDER IRRIGATION DISTRICT",
      "StreetAddress": "HC 33 BOX 1026",
      "City": "BOISE",
      "State": "ID",
      "ZIP": "83706",
      "BusinessPhone": "208-459-3421"
    },
    {
      "OrganizationName": "WOOD RIVER VALLEY IRRIGATION DISTRICT #45",
      "StreetAddress": "PO BOX 2223",
      "City": "HAILEY",
      "State": "ID",
      "ZIP": "83333",
      "BusinessPhone": "208-309-0965"
    }
  ];

  export default irrigationDISTRICTContacts; 