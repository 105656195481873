//--------------------------------------------------
//Login Modal
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';

import modalImg from '../../Images/Groundwater_Map.png';
import modalImg2 from '../../Images/modalImg2.png';
import modalImg3 from '../../Images/modalImg3.png';
import modalImg4 from '../../Images/watershedMap.PNG';

const LoginModal = ({ show, onHide, loggedIn }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const images = [
        modalImg, modalImg2, modalImg3, modalImg4
    ];

    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex(prevIndex =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
        }, 5000);
    
        return () => clearInterval(interval);
      }, [images.length]);

    return (
        <Fragment>
            {loggedIn ? 
                <Modal show={show} onHide={onHide} size='lg'>
                    <Modal.Header>
                        <Modal.Title>Your Account</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                       
                    </Modal.Body>

                    <Modal.Footer>
                        <div>
                            <button className='dismiss' onClick={onHide}>Close</button>
                            <button>Log Out</button>
                        </div>
                    </Modal.Footer>
                </Modal>
                
                :
                 
                <Modal show={show} onHide={onHide} size='lg'>
                    <Modal.Header>
                        <div className="carousel-indicators">
                                {images.map((_, index) => (
                                <span
                                    key={index}
                                    className={`carousel-indicator ${index === activeIndex ? 'active' : ''}`}
                                ></span>
                                ))}
                        </div>
                        <Modal.Title>Welcome to the Treasure Valley Water Hub</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='carousel'>
                            <div className='carousel-inner'>
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                                >
                                    <img src={image} alt={`Image ${index}`} />
                                </div>
                            ))}
                            </div>
                        </div>
                        <h3>Explore the world of water now!</h3>
                        <p>Create a free account and upgrade to <Link to="0" onClick={onHide}><button className='premium-button'>Premium</button></Link> for access to more data and tools!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <div>
                            <button className='dismiss' onClick={onHide}>Dismiss</button>
                            <Link to="/login/Login" onClick={onHide}>
                                <button className='sign-up'>Sign Up/Log In</button>
                            </Link>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(LoginModal);
export { connection as LoginModal };