//--------------------------------------------------
//Treasure Valley Water Atlas Landing Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import tvwateratlas1 from '../../Images/tvwateratlas1.jpg';
import tvwateratlas2 from '../../Images/tvwateratlas2.png';
import tvwateratlas3 from '../../Images/tvwateratlas3.png';
import tvwateratlas4 from '../../Images/tvwateratlas4.jpg';
import tvwateratlas5 from '../../Images/tvwateratlas5.png';
import tvwateratlas6 from '../../Images/tvwateratlas6.jpg';
import tvwateratlas7 from '../../Images/tvwateratlas7.jpg';

import logo from '../../Images/tv-water-atlas-logo2.png'

const TVWaterAtlas = ({ }) => {

    const narratives = [
        { name: 'Source to Sink', content: 'Learn about the components of the water cycle and water balance in the context of the Treasure Valley.', img: tvwateratlas1, path: 'https://storymaps.arcgis.com/stories/d3e5c0a2a73a4d65876456c5c531aa01' },
        { name: 'History', content: 'Learn about the hydrologic and geological context of the Treasure Valley, and how agricultural irrigation transformed the landscape.', img: tvwateratlas2, path: 'https://storymaps.arcgis.com/stories/a9817b80e1394dc19e832704fd794bf8' },
        { name: 'Water Law', content: 'Learn about Western water law and water rights in Idaho.', img: tvwateratlas3, path: 'https://storymaps.arcgis.com/stories/3c40926e323c4cd39b9eec5012a2ad53' },
        { name: 'Water Management', content: 'Learn who the key players in water management and delivery are in the Treasure Valley.', img: tvwateratlas4, path: 'https://storymaps.arcgis.com/stories/9656d06a91c2446ba21014bc5f4e9a4e' },
        { name: 'Farmland & Urban Growth', content: 'Learn how agricultural and urban water use affects our groundwater supply.', img: tvwateratlas5, path: 'https://storymaps.arcgis.com/stories/6cf50b67b6ed403ba6cb9988bf4c09f2' },
        { name: 'Climate Change', content: 'Learn how climate change affects the timing of surface water availability.', img: tvwateratlas6, path: 'https://storymaps.arcgis.com/stories/b4dc55fa5dc6453faf32dbb543b57a1e' },
        { name: 'Take Action', content: 'Learn how your community can take action to conserve and share water.', img: tvwateratlas7, path: 'https://storymaps.arcgis.com/stories/0e2ec5f796724360b8ff0e3eb7cb5ca9' },
    ];

    return (
        <Fragment>
            <div className='water-atlas-container'>
                <div className='tv-logo-holder'>
                    <img src={logo}></img>
                    <h2>Treasure Valley <span>Water</span> Atlas</h2>
                </div>
                <p className='narrative-text'>These narratives are a resource for decision-makers, educators, and water-users who care and wish to know more about water in the Treasure Valley. You can explore the source and history of our water, read about water law and management, learn about impacts to the water we use, and learn how to take action.</p>
                
                <div className='story-map-bar'>
                    {narratives.map((narrative, index) => (
                        <Link to={narrative.path} target='_blank'>
                            <button key={index}>{narrative.name}</button>
                        </Link>
                    ))}
                </div>
                <h2 className='water-atlas-sub-header'>Explore the Narratives</h2>
                <hr></hr>

                <div className='narrative-container'>
                    <div className='narratives'>
                        {narratives.map((narrative, index) => ( 
                            <div className='row story-container' key={index}>
                            {index % 2 === 0 ? (
                                <>
                                <div className='col-6'>
                                    <Link to={narrative.path} target='_blank'>
                                        <img className='story-img' src={narrative.img}></img>
                                    </Link>
                                </div>
                                <div className='col-1'></div>
                                <div className='col-5 story-text'>
                                    <h2>{narrative.name}</h2>
                                    <p>{narrative.content}</p>
                                    <Link to={narrative.path} target='_blank'>
                                        <span>See More &gt;</span>
                                    </Link>
                                </div>
                                </>
                            ) : (
                                <>
                                <div className='col-5 story-text'>
                                    <h2>{narrative.name}</h2>
                                    <p>{narrative.content}</p> 
                                    <Link to={narrative.path} target='_blank'>
                                        <span>See More &gt;</span>
                                    </Link>
                                </div>
                                <div className='col-1'></div>
                                <div className='col-6'>
                                    <Link to={narrative.path} target='_blank'>
                                        <img className='story-img' src={narrative.img}></img>
                                    </Link>
                                </div>
                                </>
                            )}
                            </div>
                        ))}
                    </div>       
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(TVWaterAtlas);
export { connection as TVWaterAtlas };