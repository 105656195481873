//--------------------------------------------------
//The Confluence Project (TCP)
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';

import TCP1 from '../../Images/TCP1.png'
import TCP2 from '../../Images/TCP2.png'
import TCP3 from '../../Images/TCP3.png'
import TCP4 from '../../Images/TCP4.png'
import tcpHr from '../../Images/tcp-hr.svg'
import christaPic from '../../Images/christaPic.png'

const TheConfluenceProject = ({ }) => {
    return (
        <Fragment>
            <div className='tcp-container'>
                <h1>The Confluence Project</h1>
                <img src={tcpHr}></img>

                <br /><br />
                <div className='tcp-content row'>
                    <div className='col-8'>
                        <h2>Overview</h2>
                        <p>The Confluence Project is <a href='https://www.uidaho.edu/cda/iwrri-cwrc/confluence' target='_blank'>University of Idaho's</a> year-long watershed education program pairing scientific experts with high school learners through field studies and classroom visits. TCP connects nearly 500 participants each year to Northern Idaho’s lakes, streams, mountains, and aquifer thanks to strong community partnerships.</p>
                    </div>
                    <div className='col-4'>
                        <img src={TCP1}></img>
                    </div>
                </div>
                <br />
                <div className='tcp-content row'>
                    <div className='col-5'>
                        <img src={TCP4}></img>
                    </div>
                    <div className='col-7'>
                        <h2>Expansion in the Treasure Valley</h2>
                        <p>In the Treasure Valley, the Idaho Water Resources Research Institute (IWRRI) and the University of Idaho Extension are teaming up with local community partners–including the City of Boise WaterShed, Bogus Basin, Ada Soil & Water Conservation District, Idaho Department of Environmental Quality, Idaho Department of Health & Welfare, and more–to bring TCP to high schools and middle schools in the Boise River Watershed, from Idaho City to Middleton.</p>
                    </div>
                </div>
                <br />
                <div className='tcp-content row'>
                    <div className='col-8'>
                        <h2>Classroom and Fieldwork Activities</h2>
                        <p>Throughout the school year, TCP teachers introduce water resource issues in the classroom and guide their students in a comprehensive research project. During multiple field excursions, students collect and analyze local water resource data. In the spring, they develop a driving question related to a real-world water resource issue, thoroughly research, collect data, and propose a relevant solution.</p>
                    </div>
                    <div className='col-4'>
                        <img src={TCP3}></img>
                    </div>
                </div>
                <br />
                <div className='tcp-content row'>
                    <div className='col-5'>
                        <img src={TCP2}></img>
                    </div>
                    <div className='col-7'>
                        <h2>The Youth Water Summit</h2>
                        <p>The project culminates in the Youth Water Summit where students present their research projects. More than 100 volunteer judges from the scientific community contribute to the success and growth of this event. The Youth Water Summit encourages students to dig deeper into local water issues while providing an opportunity to experience a professional scientific conference.</p>
                    </div>
                </div>
                <br />
                <div className='tcp-content row'>
                    <div className='col-7'>
                        <h2>Volunteer with the Confluence Project</h2>
                        <p>TCP is truly a community-led effort requiring the assistance, expertise and passion of hundreds of volunteers every year. If you have experience in environmental science, water quality issues or other applicable fields and would like to support TCP during field trips or as a summit judge, contact the project team at chowarth@uidaho.edu</p>
                    </div>
                    <div className='col-5'>
                        <div className='row contact-card'>
                            <div className='col-5'>
                                <img src={christaPic}></img>
                            </div>
                            <div className='col-7'>
                                <div>
                                    <h3>Christa Howarth</h3>
                                    <h4>Extension Water Educator</h4>
                                    <p>They/Them</p>
                                </div>
                            </div>
                            <p><br />The University of Idaho<br />College of Agricultural and Life Sciences<br />Idaho Water Center<br />322 East Front St.<br />Boise, ID 83702<br /><a href='mailto: chowarth@uidaho.edu'>chowarth@uidaho.edu</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(TheConfluenceProject);
export { connection as TheConfluenceProject };