//--------------------------------------------------
//Map of the US React-Leaflet Map and GeoJSON
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Slider from '@mui/material/Slider';
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { LineGraph } from '../DataVisualizations/linegraph.js';
import { PieChart } from '../DataVisualizations/piechart.js';
import { BarGraph } from '../DataVisualizations/bargraph.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Dropdown from 'react-bootstrap/Dropdown';

import countyData from '../../Data/counties'
import waterUseData from '../../Data/waterUse.js'
import wellPermitsData from '../../Data/wellPermitsData.js'
import wellPermitsDataGrouped from '../../Data/wellPermitsDataGrouped.js'
import L, { bounds } from 'leaflet';
import * as d3 from 'd3';
import {
  MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents, LayersControl,
  LayerGroup, FeatureGroup, GeoJSON, Tooltip, Circle, Polygon, Polyline, CircleMarker, SVGOverlay, ZoomControl
} from 'react-leaflet';

import { bottom } from '@popperjs/core';

import leftArrow from '../../Images/left-arrow.svg'
import rightArrow from '../../Images/right-arrow.svg'
import watermapKey from '../../Images/watermap-key.svg'
import watermapKeyDivider from '../../Images/watermap-key-divider.svg'
import enlargeBtn from '../../Images/enlarge-button.svg'
import unenlargeBtn from '../../Images/unenlarge-button.svg'

const idahoCounties = [
  "ADA", "ADAMS", "BANNOCK", "BEAR LAKE", "BENEWAH", "BINGHAM", "BLAINE", "BOISE", "BONNER", "BONNEVILLE", "BOUNDARY",
  "BUTTE", "CAMAS", "CANYON", "CARIBOU", "CASSIA", "CLARK", "CLEARWATER", "CUSTER", "ELMORE", "FRANKLIN", "FREMONT", "GEM",
  "GOODING", "IDAHO", "JEFFERSON", "JEROME", "KOOTENAI", "LATAH", "LEMHI", "LEWIS", "LINCOLN", "MADISON", "MINIDOKA",
  "NEZ PERCE", "ONEIDA", "OWYHEE", "PAYETTE", "POWER", "SHOSHONE", "TETON", "TWIN FALLS", "VALLEY", "WASHINGTON"
];

const years = [
  "1985", "1990", "1995", "2000", "2005", "2010", "2015"
];

/* component that creates a polygon on the map based on geoJSON data */
const StateGeoJSON = ({ data, style, setCounty }) => {
  const map = useMap();

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.NAME) {
      layer.bindTooltip(feature.properties.NAME, {
        sticky: true,
        direction: 'auto',
        className: 'custom-tooltip'
      });
    }
  }

  const handleStateClick = (e) => {
    let stateFeature;

    if (e.layer) {
      stateFeature = e.layer.feature;
    } else if (e.target) {
      stateFeature = e.target.feature;
    }

    if (stateFeature) {
      const bounds = L.geoJSON(stateFeature).getBounds();
      const center = bounds.getCenter();
      map.flyTo(center, 10);
    }
    setCounty(e.layer.feature.properties.NAME.toUpperCase());
  };

  return (
    <GeoJSON data={data} style={style} eventHandlers={{ click: handleStateClick }} onEachFeature={onEachFeature} />
  );
};

const GroundwaterLevelsGeoJson = ({ data, geoJSONWaterStyle, markerIcon, onEachWell }) => {
  const map = useMap();
  useEffect(() => {
    // Assuming you have a reference to your GeoJSON layer
    if (map && data) {
      const layer = L.geoJSON(data, {
        style: geoJSONWaterStyle,
        pointToLayer: (feature, latlng) => {
          // Extract the iconName property from the feature's properties
          const iconName = feature.properties.iconName;
          // Pass the iconName to the markerIcon function
          return L.marker(latlng, { icon: markerIcon(iconName) });
        },
        onEachFeature: onEachWell
      });
      layer.addTo(map);

      return () => {
        map.removeLayer(layer); // Clean up on unmount or when data changes
      };
    }
  }, [map, data]);

  return null;
};

/* map search bar component. lets you search addresses */
const SearchField = (props) => {
  const map = useMap();

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      style: "bar",
      marker: {
        icon: new L.icon({
          iconUrl:
            "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
          iconSize: [25, 41],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40]
        })
      },
      ...props
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [map, props]);

  return null;
};

/* Custom map layer control component. */
const MapLayerControl = ({ layerId, layerName, handleMapLayer, isCheckedAtStart }) => {
  const [isChecked, setIsChecked] = useState(isCheckedAtStart); //determines whether the checkbox starts out checked or not
  //function for checking/unchecking the checkbox for the given layer
  const handleCheckboxClick = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    // Call the passed-in function with the new checked state
    handleMapLayer(newCheckedState);
  };

  //returns one checkbox with all the passed-in variables
  return (
    <div className='layers-checkboxes'>
      <label for={layerId} className='custom-checkbox'>
        <input type="checkbox" checked={isChecked} id={layerId} name={layerId} onChange={handleCheckboxClick} />
        {layerName} </label>
    </div>
  )
};

/* Water map component */
const WaterMap = ({ session, crud, FindMetaData, FindAdditionalMetaData, GetGroundWaterData }) => {
  //state variable for holding data from database
  const [metadata, setMetadata] = useState([]); //groundwater metadata
  const [addMetaData, setAddMetaData] = useState([]); //groundwater metadata
  const [metaDataGeoJson, setMetaDataGeoJson] = useState([]);
  const [addMetaDataGeoJson, setAddMetaDataGeoJson] = useState([]);
  const [selectedStationName, setSelectedStationName] = useState([]);
  const [GWData, setGWData] = useState();
  const [lineData, setLineData] = useState();
  const [county, setCounty] = useState("Select County");
  const [dataType, setDataType] = useState("Continuous");
  const [enlarge, setEnlarge] = useState(false);
  const lineGraphWrapperRef = useRef(null);
  const pieChartWrapperRef = useRef(null);
  const barGraphWrapperRef = useRef(null);

  //state variables for each layer's visibility
  const [isCountiesLayerVisible, setCountiesLayerVisibility] = useState(true);
  const [areGroundwaterMarkersVisible, setGroundwaterMarkersVisibility] = useState(true);

  //pie graph
  const [refinedWaterUseData, setRefinedWaterUseData] = useState([waterUseData]);
  const [waterUseDataYear, setWaterUseDataYear] = useState("Select Year");

  //map variables
  const center = [43.5, -116];
  const bounds = [
    [center[0] - 10.0, center[1] - 10.0], // Southwest corner
    [center[0] + 10.0, center[1] + 10.0]  // Northeast corner
  ];

  //for back button on navbar
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  //get the groundwater metadata on component mount
  useEffect(() => {
    FindMetaData(dataType);
  }, [dataType]);

  useEffect(() => {
    setMetadata(session.metaData);
    setAddMetaData(session.addMetaData);
  }, [session.metaData, session.addMetaData])
  //convert metadata to geoJSON whenever metadata changes
  useEffect(() => {
    if (Array.isArray(metadata) && metadata.length > 0) {
      setMetaDataGeoJson(convertToGeoJSON(metadata));
    } else {
      //console.log("no metadata yet");
    }
    if (Array.isArray(addMetaData) && addMetaData.length > 0) {
      if (addMetaData[0].county != "CANYON" && addMetaData[0].county != "ADA") {
        setAddMetaDataGeoJson(convertToGeoJSON(addMetaData));
      }
    } else {
      //console.log("no addMetaData yet");
    }
  }, [metadata, addMetaData]);

  //when a station is selected (by clicking on a marker), get the discrete groundwater data
  useEffect(() => {
    //console.log("selectedStationName: ", selectedStationName);
    GetGroundWaterData(selectedStationName, dataType);
  }, [selectedStationName, dataType]);

  //save the station's  data to the state var GWData
  useEffect(() => {
    setGWData(session.theData);
  }, [session.theData]);

  //convert the discrete data to a format useable by the linegraph
  useEffect(() => {
    if (GWData) {
      setLineData(FormatDataForLineGraph(GWData));
    }
  }, [GWData]);

  const convertToGeoJSON = (data) => {
    if (!Array.isArray(data)) {
      //console.error("Expected data to be an array, but got:", data);
      return;
    }

    return {
      type: "FeatureCollection",
      features: data.map((item) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
        },
        properties: {
          stationName: item.station_name,
          depthToWater: item.depth_to_water_ft,
          dateTime: item.date_time,
          iconName: item.groundwater_level_trend,
        }
      }))
    };
  };

  //the icon used when placing a marker and for NewWellData
  const markerIcon = (iconName = 'map-marker-icon') => L.icon({
    iconUrl: require(`../../Images/${iconName}.png`),
    iconSize: [20, 15],
    iconAnchor: [10, 25],
    popupAnchor: [0, -20],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, {
      radius: 8,
      fillColor: 'blue',
      color: 'blue',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });
  };

  const prov = new OpenStreetMapProvider(); //map provider. used in the SearchField component 

  //a style for outlining and filling a given area, such as the counties, lakes, drawn polygons, etc.
  const geoJSONWaterStyle = {
    color: "blue",
    weight: 2,
    fillColor: "lightblue",
    fillOpacity: 0.5
  };

  //adds a tooltip with the feature's name (like lakes and rivers)
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.NAME) {
      layer.bindTooltip(feature.properties.NAME, { sticky: true });
    }
  };

  function FormatDate(dateTime) {
    //Create a new Date object
    const date = new Date(dateTime);
    // Extract the month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Format the date as mm-dd-yyyy
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }

  function FormatDataForLineGraph(data) {
    const formattedLineData = data.map(data => ({
      x: new Date(data.date_time),
      y: parseFloat(data.depth_to_water_ft)
    }));
    //console.log("formattedLineData: ", formattedLineData);
    return formattedLineData;
  }

  //adds a tooltip on hover with some information about the well, and adds a popup on click with all of the info
  const onEachWell = (feature, layer) => {
    if (!feature.properties || !feature.geometry) return;

    const contentValues = Object.keys(feature.properties).map(key => {
      return feature.properties[key] !== null && feature.properties[key] !== "" ? feature.properties[key] : "not collected";
    });

    const tooltipContent = `
            <div>
              <strong>Station Name:</strong> ${contentValues[0]}<br/>
              <strong>Most Recent Depth to Water Measurement (feet):</strong> ${contentValues[1]}<br/>
              <strong>Date of Measurement:</strong> ${FormatDate(contentValues[2])}<br/>
              <strong>Click on the icon to bring up more information!</strong>
            </div>
          `;

    const popupContent = `
            <div>
              <strong>Station Name:</strong> ${contentValues[0]}<br/>
              <strong>Latitude:</strong> ${feature.geometry.coordinates[1]}<br/>
              <strong>Longitude:</strong> ${feature.geometry.coordinates[0]}<br/>
              <strong>Most Recent Depth to Water Measurement (feet):</strong> ${contentValues[1]}<br/>
              <strong>Date of Measurement:</strong> ${FormatDate(contentValues[2])}<br/>
            </div>
          `;

    layer.bindTooltip(tooltipContent, {
      permanent: false,
      direction: 'top',
      offset: L.point(1, -20)
    });

    layer.bindPopup(popupContent, {
      maxWidth: 200,
      closeButton: true
    });

    layer.on('click', () => {
      setSelectedStationName(contentValues[0]);
      //console.log("set station name to: ", contentValues[0]);
    })

  };

  const handleCountySelect = (county) => {
    setCounty(county);
    FindAdditionalMetaData(county, dataType);
    setRefinedWaterUseData(waterUseData.filter(item => item.county == county));
  };

  const handleDataSelect = (dataType) => {
    setDataType(dataType);
    FindMetaData(dataType);
  };

  const handleWaterUseYearSelect = (year) => {
    setWaterUseDataYear(year);
  }

  const handleSetEnlarge = (value) => {
    //console.log("Enlarge ", enlarge);
    setEnlarge(value);
  }

  /* lets us add the container for the graphs and map key on top of the map */
  function KeyAndGraphs() {
    const parentMap = useMap();

    const disableMapInteractions = () => {
      parentMap.dragging.disable();
      parentMap.scrollWheelZoom.disable();
      parentMap.doubleClickZoom.disable();
      parentMap.boxZoom.disable();
      parentMap.keyboard.disable();
    };

    const enableMapInteractions = () => {
      parentMap.dragging.enable();
      parentMap.scrollWheelZoom.enable();
      parentMap.doubleClickZoom.enable();
      parentMap.boxZoom.enable();
      parentMap.keyboard.enable();
    };

    /* This is for the map filters (implement later)
              <div className='map-layers-controller'>
            <MapLayerControl
              layerId="counties"
              layerName='Counties'
              handleMapLayer={setCountiesLayerVisibility}
              isCheckedAtStart={true}
            />
            <MapLayerControl
              layerId="groundwaterLevelMarkers"
              layerName='Groundwater Level Markers'
              handleMapLayer={setGroundwaterMarkersVisibility}
              isCheckedAtStart={true}
            />
          </div>
    */

    return (
      <div className={`key-and-graphs-container ${enlarge ? 'enlarged' : ''}`} onMouseDown={disableMapInteractions} onMouseEnter={disableMapInteractions} onMouseLeave={enableMapInteractions}>
        <div className='key-and-graphs-title'>
          <h1>Idaho Groundwater Levels</h1>
        </div>
        <div className='graphs' ref={lineGraphWrapperRef}>
          <button className='prev-arrow'>
            <img src={leftArrow}></img>
          </button>
          <Swiper
            navigation={{ nextEl: '.next-arrow', prevEl: '.prev-arrow' }}
            slidesPerView={1}
            setWrapperSize={false}
            spaceBetween={2}
            modules={[Navigation]}
            className="graphSwiper"
          >
            {/* Line Graph */}
            <SwiperSlide ref={lineGraphWrapperRef}>
              {lineData ? (
                <>
                  <div className='GroundwaterLineGraph' >
                    {lineData.length > 0 ? (
                      <>
                        {lineData.length > 1 ? (
                          <>
                            <h1>Groundwater Levels Over Time: Station {GWData[0].station_name}</h1>
                            <LineGraph
                              data={lineData}
                              selectedGroup='y'
                              targetRef={lineGraphWrapperRef}
                              isTimeScale={true}
                              xAxisLabel='Date'
                              yAxisLabel={`Depth to Water\nBelow Ground Surface in Feet`}
                              tooltipTitle={"Station " + GWData[0].station_name}
                            />
                          </>
                        ) : (
                          <p className="graph-notice">There is not enough data to make a line graph for the station {GWData[0].station_name}. A station must have at least two recorded measurements to generate a line graph. Try clicking on a different station's marker to generate a line graph of that station's groundwater levels over time.</p>
                        )}
                      </>
                    ) : null}
                  </div>
                </>
              ) : <p className="graph-notice">Select a station's marker on the map to generate a line graph of it's groundwater levels over time.</p>}
            </SwiperSlide>

            {/* Bar Graph */}
            {/* <SwiperSlide>
                <div className='GroundwaterBarGraph' ref={barGraphWrapperRef}>
                  
                  <BarGraph
                    data={wellPermitsDataGrouped}
                    targetRef={barGraphWrapperRef}
                  >
                  </BarGraph>
                  
                </div>
            </SwiperSlide> */}

            {/* Pie Chart */}
            <SwiperSlide>
              {county != "Select County" && waterUseDataYear != "Select Year" ?
                <div className='groundwaterPieChart-container'>
                  <div className='GroundwaterPieChart' ref={pieChartWrapperRef}>
                    {refinedWaterUseData.length != 0 ?
                      <PieChart
                        data={refinedWaterUseData}
                        targetRef={pieChartWrapperRef}
                        year={waterUseDataYear}
                        county={county}
                        noData={false}
                      >
                      </PieChart>
                      :
                      <PieChart
                        data={refinedWaterUseData}
                        targetRef={pieChartWrapperRef}
                        year={waterUseDataYear}
                        county={county}
                        noData={true}
                      >
                      </PieChart>}

                  </div>
                </div>
                :
                <div>
                  <p className="graph-notice">To generate a pie chart of water use, please select a county and year.</p>
                </div>
              }
            </SwiperSlide>
          </Swiper>
          <button className='next-arrow'>
            <img src={rightArrow}></img>
          </button>
          {enlarge ?
            <img className='unenlarge-button' src={unenlargeBtn} onClick={() => handleSetEnlarge(false)}></img>
            :
            <img className='enlarge-button' src={enlargeBtn} onClick={() => handleSetEnlarge(true)}></img>
          }

        </div>
        {enlarge ?
          <></>
          :
          <div className='key-and-text-container'>
            <div className='key'>
              <div className='key-title'>
              <span class="material-symbols-outlined">
                  info
                  <p id="popup-text">Groundwater level trends were calculated using linear regression on groundwater level measurements taken by the IDWR. Trends were only calculated if the station had more than 4 measurements taken over 2 years, or 10 measurements total. Stations that were calculated to have a slope greater than 0.25 are considered to have an increasing trend, station with a slope less than -0.25 are considered to have a decreasing trend, and stations with a slope between 0.25 and -0.25 are considered to have a steady trend.</p>
                </span>
                <h2>Water Level Map Key</h2>
              </div>
              <img src={watermapKey}></img>
            </div>
            <img id='key-divider' src={watermapKeyDivider}></img>
            <div className='map-control-buttons-container'>
              <div className='dropdown-label'>
                <p>County</p>
                {/*<span class="material-symbols-outlined">
                  info
                </span>*/}
              </div>
              <Dropdown className='map-control-button'>
                <Dropdown.Toggle>
                  {county}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {idahoCounties.map((county, index) => (
                    <Dropdown.Item key={index} onClick={() => handleCountySelect(county)}>{county}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <div className='dropdown-label'>
                <p>Water Use Year</p>
                {/*<span class="material-symbols-outlined">
                  info
                </span>*/}
              </div>

              <Dropdown className='map-control-button'>
                <Dropdown.Toggle>
                  {waterUseDataYear}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {years.map((year, index) => (
                    <Dropdown.Item key={index} onClick={() => handleWaterUseYearSelect(year)}>{year}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <div className='dropdown-label'>
                <p>Data Type</p>
                {/*<span class="material-symbols-outlined">
                  info
                </span>*/}
              </div>
              <Dropdown className='map-control-button'>
                <Dropdown.Toggle>
                  {dataType}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleDataSelect('Discrete')}>Discrete</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDataSelect('Continuous')}>Continuous</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <Fragment>
      <div className='main-container map-page'>
        <div className='map-container'>
          <MapContainer center={center} zoom={8} maxBounds={bounds} maxBoundsViscosity={1.0} minZoom={7}>
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />

            <KeyAndGraphs />

            <SearchField
              provider={prov}
              showMarker={true}
              showPopup={true}
              popupFormat={({ query, result }) => result.label}
              maxMarkers={3}
              retainZoomLevel={false}
              animateZoom={true}
              autoClose={false}
              searchLabel={"Enter address"}
              keepResult={true}
            />

            {isCountiesLayerVisible &&
              <StateGeoJSON data={countyData} style={geoJSONWaterStyle} setCounty={handleCountySelect} id="county" />
            }

            {areGroundwaterMarkersVisible &&
              <>
                <GroundwaterLevelsGeoJson data={metaDataGeoJson} geoJSONWaterStyle={geoJSONWaterStyle} markerIcon={markerIcon} onEachWell={onEachWell} ></GroundwaterLevelsGeoJson>
                <GroundwaterLevelsGeoJson data={addMetaDataGeoJson} geoJSONWaterStyle={geoJSONWaterStyle} markerIcon={markerIcon} onEachWell={onEachWell} ></GroundwaterLevelsGeoJson>
              </>
            }

          </MapContainer>
        </div>
      </div>
    </Fragment >
  )
};

function mapState(state) {
  const { session, crud } = state;
  return { session, crud };
}

const actionCreators = {
  FindMetaData: userActions.FindMetaData,
  FindAdditionalMetaData: userActions.FindAdditionalMetaData,
  GetGroundWaterData: userActions.GetGroundWaterData,
};

const connection = connect(mapState, actionCreators)(WaterMap);
export { connection as WaterMap };