//--------------------------------------------------
//Water Organization Directory
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

import IDHW from '../../Images/IDHW.png'
import IDEQ from '../../Images/IDEQ.png'
import IDWR from '../../Images/IDWR.png'
import ASWCD from '../../Images/ASWCD.png'
import USGS from '../../Images/USGS.png'
import USDA from '../../Images/USDA.png'
import TheWatershed from '../../Images/TheWatershed.png'
import CityofNampa from '../../Images/CityofNampa.png'
import IWUA from '../../Images/IWUA.png'
import BREN from '../../Images/BREN.png'
import IRU from '../../Images/IRU.png'
import TroutUnlimited from '../../Images/TroutUnlimited.png'

// Template
// { header: '', content: '', bullet1: '', bullet2: '', path: '', img: blank, backgroundColor: '', size: '', imgSide: '', filter: '' },

const organizations = [
    //State Agencies
    { header: 'Idaho Department of Health and Welfare', content: 'Do you know where your drinking water comes from or what is in it? 30% of Idahoans have made private well water their primary source of water. Due to inadequate water testing, you may be exposed to groundwater contaminants that pose health risks. How long has it been since you had your well water tested? If you are a private well owner, you are responsible for ensuring that the well water is safe to drink!', bullet1: 'Learn how to get your private well water tested', bullet2: 'Learn what steps to take if your water sample test shows contaminants', path: 'https://healthandwelfare.idaho.gov/health-wellness/environmental-health/drinking-water', img: IDHW, backgroundColor: '#A9C9E6', size: 'full', imgSide: 'left', filter: 'state' },
    { header: 'Idaho Department of Environmental Quality', content: 'DEQ’s Water Quality Divisions ensure the state’s surface, ground, and drinking water resources meet state water quality standards. ', bullet1: 'Learn about state water quality standards and regulations', bullet2: 'Learn about safe waste disposal practices to protect source water', path: 'https://www.deq.idaho.gov/water-quality/', img: IDEQ, backgroundColor: '#2E3978', size: 'half', imgSide: 'top', filter: 'state' },
    { header: 'Idaho Department of Water Resources', content: 'IDWR’s mission is to serve Idahoans by ensuring their water is conserved and available to sustain Idaho’s economy, ecosystems, and resulting quality of life.', bullet1: 'Learn about water rights in Idaho', bullet2: 'Access IDWR’s water data on groundwater, rights, and supply', path: 'https://idwr.idaho.gov/', img: IDWR, backgroundColor: '#C6E8F1', size: 'half', imgSide: 'top', filter: 'state' },
    { header: 'Ada County Soil & Water Conservation District', content: 'The Ada Soil & Water Conservation District is a catalyst for implementing natural resource conservation programs, and channeling expertise and assistance from all levels of government into action at the local level. As a governmental subdivision of the state of Idaho, we’ve been leading non-regulatory efforts to conserve, sustain, and enhance natural resources in Ada County since 1948. We are local leaders here to provide technical, financial, and educational assistance to private landowners and our community partners to help conserve soil, water, air, plant and animal resources.', bullet1: 'Find community education workshops like the Water Wise landscaping series', bullet2: 'Learn about small grants for soil conservation', path: 'https://www.adaswcd.org/', img: ASWCD, backgroundColor: '#D3DBED', size: 'full', imgSide: 'left', filter: 'state' },

    //Federal Agencies 
    { header: 'US Geological Survey', content: 'Water information is fundamental to national and local economic well-being, protection of life and property, and effective management of the Nation’s water resources. The USGS works with partners to monitor, assess, conduct targeted research, and deliver information on a wide range of water resources and conditions including streamflow, groundwater, water quality, and water use and availability.', bullet1: 'Access StreamStats for data on streamflow in user-delineated drainages', bullet2: 'View water data visualizations', path: 'https://www.usgs.gov/mission-areas/water-resources', img: USGS, backgroundColor: '#B6D2CA', size: 'half', imgSide: 'top', filter: 'federal' },
    { header: 'USDA Natural Resource Conservation Service', content: 'Farmers, ranchers, and forest landowners recognize water as our Nation’s most precious resource. Every day, new producers are stepping up to work hand-in-hand with NRCS to implement systems that conserve water and keep valuable nutrients on the field and out of local waterways.', bullet1: 'Learn about water quality and conservation practices for farmers, ranchers, and forest landowners', bullet2: 'Find assistance resources for conservation practices', path: 'https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns/water', img: USDA, backgroundColor: '#CDDDED', size: 'half', imgSide: 'top', filter: 'federal' },

    //City Departments
    { header: 'City of Boise, Boise Watershed', content: 'The WaterShed gives visitors a sense of place in the Boise River Watershed, highlighting the essential resource that defines the Treasure Valley. The exhibits, tours and programs also educate and engage residents on the importance of Boise’s Water Renewal Services, the city’s water renewal (wastewater) facility. Learn more about how the utility plays a key role in protecting human health and ensuring clean water for our community and environment.', bullet1: 'Visit the WaterShed for a tour of Boise’s Wastewater renewal facility', bullet2: 'Check out educational water programming for K-12 students', path: 'https://www.boisewatershed.org/', img: TheWatershed, backgroundColor: '#000000', size: 'full', imgSide: 'left', filter: 'city' },
    { header: 'City of Nampa, Waterworks Division', content: 'The Waterworks Division provides Nampa residents with over 9.7 million gallons of safe drinking water each day. The Waterworks Division also provides Nampa residents with over 44.7 million gallons of irrigation water each day.', bullet1: 'Learn about domestic and irrigation water for Nampa residents', bullet2: 'Learn about Water Wise landscaping practices', path: 'https://www.cityofnampa.us/292/Waterworks-Division', img: CityofNampa, backgroundColor: '#D2DBE9', size: 'full', imgSide: 'right', filter: 'city' },

    //Non-Profits
    { header: 'Treasure Valley Water Users Association', content: 'Treasure Valley irrigation delivery entities and our water user community face many common challenges with respect to their Boise River Basin water rights, water supplies and the changing land uses and demographics of the Treasure Valley. As a result, the Treasure Valley Water Users Association has been developed to address the need for coordinated collaboration among Treasure Valley water delivery entities for the mutual benefit of their respective water users.', bullet1: 'Learn about canal and lateral safety', bullet2: '', path: 'http://www.treasurevalleywaterusers.com/', img: IWUA, backgroundColor: '#0543E4', size: 'full', imgSide: 'left', filter: 'non-profit' },
    { header: 'Boise River Enhancement Network', content: 'The Boise River is recognized as a valuable resource by all communities in the Treasure Valley. Boise River Enhancement Network participants envision a healthy Boise River that enriches the Valley’s quality of life.', bullet1: 'Learn about the Boise River Enhancement Plan', bullet2: 'Find events and volunteer opportunities', path: 'https://www.boiseriverenhancement.org/', img: BREN, backgroundColor: '#FCFDFF', size: 'half', imgSide: 'left', filter: 'non-profit' },
    { header: 'Idaho Rivers United', content: 'Idaho is home to 107,651 miles of river, and Idaho Rivers United is the only conservation organization in our state focused exclusively on the health and protections of our incredible river resources.', bullet1: 'Learn how IRU campaigns for river restoration, fish protection, and mining reform ', bullet2: 'Find events and volunteer opportunities', path: 'https://www.idahorivers.org/', img: IRU, backgroundColor: '#000000', size: 'half', imgSide: 'left', filter: 'non-profit' },
    { header: 'Trout Unlimited', content: 'Trout Unlimited’s mission is to conserve, protect and restore North America’s trout and salmon fisheries and their watersheds. We bring together diverse interests and entities to care for and recover rivers and streams so our children can experience the joy of wild and native trout and salmon. TU accomplishes its mission on local, state and national levels with an extensive and dedicated volunteer network. ', bullet1: 'Learn about projects along the South Fork of the Boise River', bullet2: 'Find events and volunteer opportunities with the local Ted Trueblood chapter', path: 'https://tedtruebloodtu.org/?_gl=1%2A1dyzrka%2A_gcl_au%2ANzg4MzA3MDg0LjE3MjI1Mzc4NDU.%2A_ga%2AMTMyOTc3MzA2NS4xNzIyNTM3ODQ1%2A_ga_D3P9D95QQV%2AMTcyMzE0NDAzMi4yLjEuMTcyMzE0NDA3Mi4yMC4wLjA.', img: TroutUnlimited, backgroundColor: '#1F4A8A', size: 'full', imgSide: 'right', filter: 'non-profit' },
];

const WaterOrgDirectory = ({ }) => {

    useEffect(() => {
        document.body.classList.add('organization');
    
        return () => {
          document.body.classList.remove('organization');
        };
    }, []);

    const organizedData = organizations.reduce((acc, org) => {
        if (!acc[org.filter]) {
            acc[org.filter] = [];
        }
        acc[org.filter].push(org);
        return acc;
    }, {});

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      };

    return (
        <Fragment>
            <Container fluid className='organization-directory-container'>
                <h1>Water Organization's Directory</h1>
                <Row className='buttons-row'>
                    <Col md={6} className='directory-buttons'>
                        <button onClick={() => scrollToElement('state')}>State Agencies</button>
                        <button onClick={() => scrollToElement('federal')}>Federal Agencies</button>
                        <button onClick={() => scrollToElement('city')}>City Departments</button>
                        <button onClick={() => scrollToElement('non-profit')}>Non-Profits</button>
                    </Col>
                </Row>
                <div className='directory-sections-container'>
                    <div className='directory-section'>
                        {Object.entries(organizedData).map(([filter, orgs], index) => (
                            <div key={index}>
                                <br/>
                                <h2 id={filter}>{filter === 'state' ? 'State Agencies' : 
                                    filter === 'federal' ? 'Federal Agencies' :
                                    filter === 'city' ? 'City Departments' :
                                    filter === 'non-profit' ? 'Non-Profits' :
                                    filter}</h2>
                                {orgs.map((org, orgIndex) => (
                                    org.size === 'full' ?
                                        <div className='section-full' key={orgIndex}>
                                            {org.imgSide === 'left' ?
                                                <div className='full-section-left-image'>
                                                    <Link to={org.path} target='_blank'>
                                                        <div className='img-section' style={{background: org.backgroundColor}}>
                                                                <img src={org.img}></img>
                                                        </div>
                                                    </Link>
                                                    <div className='section-content'>
                                                        <h3>{org.header}</h3>
                                                        <p>{org.content}</p>
                                                        <ul>
                                                            <li>{org.bullet1}</li>
                                                            <li>{org.bullet2}</li>
                                                        </ul>
                                                        <Link to={org.path} target='_blank'>
                                                            <span>Learn more &gt;</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                :
                                                <div className='full-section-right-image'>
                                                    <div className='section-content'>
                                                        <h3>{org.header}</h3>
                                                        <p>{org.content}</p>
                                                        <ul>
                                                            <li>{org.bullet1}</li>
                                                            <li>{org.bullet2}</li>
                                                        </ul>
                                                        <Link to={org.path} target='_blank'>
                                                            <span>Learn more &gt;</span>
                                                        </Link>
                                                    </div>
                                                    <Link to={org.path} target='_blank'>
                                                        <div className='img-section' style={{background: org.backgroundColor}}>
                                                                <img src={org.img}></img>
                                                        </div>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                        : 
                                        <>
                                            {(filter === 'federal' ? orgIndex % 2 === 0 : orgIndex % 2 === 1) && 
                                            <div className='section-half' key={orgIndex}>
                                                <div className='half-section-left'>
                                                    <div className={org.imgSide === 'top' ? 'half-section-top-image' : 'half-section-left-image'}>
                                                        <Link to={org.path} target='_blank'>
                                                            <div className='img-section' style={{background: org.backgroundColor}}>
                                                                <img src={org.img}></img>
                                                            </div>
                                                        </Link>
                                                        <div className='section-content'>
                                                            <h3>{org.header}</h3>
                                                            <p>{org.content}</p>
                                                            <ul>
                                                                <li>{org.bullet1}</li>
                                                                <li>{org.bullet2}</li>
                                                            </ul>
                                                            <Link to={org.path} target='_blank'>
                                                                <span>Learn more &gt;</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='half-section-right'>
                                                    <div className={orgs[orgIndex + 1]?.imgSide === 'top' ? 'half-section-top-image' : 'half-section-left-image'}>
                                                        <Link to={orgs[orgIndex + 1]?.path} target='_blank'>
                                                            <div className='img-section' style={{background: orgs[orgIndex + 1]?.backgroundColor}}>
                                                                <img src={orgs[orgIndex + 1]?.img}></img>
                                                            </div>
                                                        </Link>
                                                        <div className='section-content'>
                                                            <h3>{orgs[orgIndex + 1]?.header}</h3>
                                                            <p>{orgs[orgIndex + 1]?.content}</p>
                                                            <ul>
                                                                <li>{orgs[orgIndex + 1]?.bullet1}</li>
                                                                <li>{orgs[orgIndex + 1]?.bullet2}</li>
                                                            </ul>
                                                            <Link to={orgs[orgIndex + 1]?.path} target='_blank'>
                                                                <span>Learn more &gt;</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(WaterOrgDirectory);
export { connection as WaterOrgDirectory };