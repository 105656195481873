//--------------------------------------------------
//Community Info - Voices of the Treasure Valley
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import img1 from '../../Images/NCP1.JPG'
import img2 from '../../Images/NCP2.JPG'
import img3 from '../../Images/NCP4.JPG'

const CommunityStories = ({ }) => {

    return (
        <Fragment>
            <Container fluid className='community-info-container'>
                <h1>Voices of the Treasure Valley</h1>
                <Container fluid className='sophias-words'>
                    <Row>
                        <Col md={8}>
                            <br/>
                            <h3>The Immerse team conducted 18 semi-structured interviews & 16 meetings about water management, equity, and data in Idaho & the Treasure Valley.</h3>
                            <br/>
                            <h4>Interviews included</h4>
                            <ul>
                                <li>Managers & decision-makers in city, county, state & federal agencies</li>
                                <li>Staff in community & environmental orgs</li>
                                <li>Irrigators, water user associations, water masters</li>
                                <li>Local residents, homeowners, & businesses</li>
                            </ul>
                            <br/>
                            <h4>Key interview results</h4>
                            <p>#1– We identified place-based water management & equity issues. 
                            <span className='bold'><br/>Impact</span>: Identified groundwater management as the an important topic in the Treasure Valley</p>
                            <br/>
                            <p><span className='bold'>Rapid urbanization & land use change are presenting critical groundwater supply issues</span> 
                            <span className='italic'><br/>“I think we're in kind of an interesting tipping point in Idaho.”</span>
                            </p>
                            <br/>
                            <p><span className='bold'>Entrenched water law & management frameworks present barriers to adaptation</span>
                            <span className='italic'><br/>“Instead of parceling [water] out in ways that are equitable in ways that contribute to a greater good, we seem to be involved in a fight to keep what little right you have to water [...]  we keep on trying to squeeze more water out of a watershed that has a finite supply.”</span>
                            </p>
                            <br/>
                            <p>#2 – We identified gaps in the data & decision-making process.
                            <span className='bold'><br/>Impact</span>: A centralized data hub will be critical, we gain momentum by starting with the water data that is most relevant for decision making.</p>
                            <br/>
                            <p><span className='bold'>Data exists but is fragmented & siloed</span>
                            <span className='italic'><br/>“The people who are managing water don't have a super organized way of sharing data with each other, and we need to be sharing data. I need to have more than just my own data when making decisions.”</span>
                            <br/>– State Agency Employee
                            </p>
                            <br/>
                            <p><span className='bold'>Better use for decision-making requires synthesis & translation</span>
                            <span className='italic'><br/>“A solution for the challenge we're facing [is] becoming more aware of how we're using water and what we're doing with it, how much is going here and there.”</span> 
                            </p>
                            <br/>
                            <p>#3 –We identified opportunities for education, outreach, and policy. 
                            <span className='bold'><br/>Impact:</span> Need to translate data into public-facing narratives & policy-relevant resources to catalyze water consciousness </p>
                            <br/>
                            <p><span className='bold'>Lack of awareness among general public</span>
                            <span className='italic'><br/>“As far as management of water [...] I don't really pay attention to it. Like if I didn't have access to it, well then I would pay more attention.”</span><br/>– Boise Realtor 
                            </p>
                            <br/>
                            <p><span className='bold'>Sense of water abundance obscures urgency </span>
                            <span className='italic'><br/>“I think we're at a place where we're blessed with an abundance of water and a dearth of knowledge about how to use it wisely.”</span>
                            – Boise River Enhancement Network Representative
                            </p>
                        </Col>
                        <Col md={4}>
                            <img src={img1}></img>
                            <img src={img2}></img>
                            <img src={img3}></img>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(CommunityStories);
export { connection as CommunityStories };