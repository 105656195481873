//--------------------------------------------------
//Immerse Landing Hub
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

import image1 from '../../Images/NCP1.JPG'
import image2 from '../../Images/NCP2.JPG'
import image3 from '../../Images/NCP3.JPG'
import image4 from '../../Images/NCP5.JPG'
import image5 from '../../Images/NCP4.JPG'
import image6 from '../../Images/NCP8.JPG'
import image7 from '../../Images/NCP9.JPG'
import image8 from '../../Images/NCP6.JPG'
import placeholder from '../../Images/placeholder.jpg'



const Immerse = ({ }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    const handleClick = (path) => {
        window.open(path, '_blank');
    };

    const handleScroll = (scroll) => {
        let element = document.getElementById(scroll);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' , block: 'center'});
        }
    }

    return (
        <Fragment>
            <div className='immerse-container'>
                <br></br><br></br>
                <div className='immerse-header'>
                    <h1>Explore. Learn. <span className='gradient-word'>Immerse. </span></h1>
                    <br></br>
                    <p>Access our portals to local water data, interactive learning experiences, and more. Immerse provides up to date and constantly evolving data centered around you and the world around you.</p>
                    <br></br>
                    <h3>Get Immersed Today</h3>
                    <br></br>
                    <div className='immerse-action-buttons'>
                        <button className='about-us' onClick={() => handleScroll('about')}>About Us</button>
                        <button className='our-portals' onClick={() => handleScroll('portal')}>Our Portals</button>
                        {/*<button className='behind-our-portals'>Our Portals</button>*/}
                    </div>
                    <br></br>
                </div>
                <br></br><br></br><br></br><br></br>
                <div className='immerse-water-database'>
                    <img src={image3}></img>
                    <img src={image2}></img>
                    <img src={image7}></img>
                    <img src={image4}></img>
                    <div className='database-words'>
                        <h3>Immerse Water Database</h3>
                        <p>The water information database compiles essential data on global hydrology, water quality, and usage patterns. It serves researchers, policymakers, and environmentalists with real-time updates, historical trends, and interactive visualizations, facilitating informed decision-making in water management and conservation.</p>
                    </div>
                </div>
                <br></br><br></br><br></br><br></br>
                <div id='about' className='about-section'>
                    <div className='database-words'>
                        <h3>About Immerse</h3>
                        <p>About Immerse Loren ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non urna quam. Cras libero metus, volutpat quis luctus vitae, blandit quis ligula. Aliquam nec suscipit augue. Vivamus molestie malesuada urna, eget interdum ipsum tincidunt in. Nulla lectus tellus, convallis sed suscipit in, efficitur sit amet turpis. Aliquam et arcu sed libero ultrices consequat. Phasellus a tortor sed tortor ultricies condimentum. Maecenas in mollis ligula, at hendrerit diam.</p>
                        <br></br>
                        <p>Nulla lectus tellus, convallis sed suscipit in, efficitur sit amet turpis. Aliquam et arcu sed libero ultrices consequat. Phasellus a tortor sed tortor ultricies condimentum. Maecenas in mollis ligula, at hendrerit diam.</p>
                    </div>
                    <div className='img-collage'>
                        <img className='about-img5' src={image5}></img>
                        <img className='about-img6' src={image6}></img>
                        <img className='about-img7' src={image1}></img>
                    </div>
                </div>
                <br></br><br></br><br></br><br></br>
                <Container fluid id='portal' className='portal-section'>
                    <h1>Explore our <span className='gradient-word'>Local Portals</span></h1>
                    <br></br>
                    <Row className='portals justify-content-center'>
                        <Col lg={4} className='portal' onClick={() => handleClick('/')} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                            {/*<div className={`behind-portal ${isHovered ? 'hovered' : ''}`}></div>*/}
                                <img className='local-portals' src={image8}></img>
                            <div className='portal-content'>
                                <h2>Treasure<br></br>Valley</h2>
                                <p>Explore the local water quality and historical trends in the Treasure Valley.</p>
                            </div>
                        </Col>
                        <Col lg={4} className='portal' onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)}>
                            {/*<div className={`behind-portal ${isHovered2 ? 'hovered2' : ''}`}></div>*/}
                                <img src={placeholder}></img>
                            <div className='portal-content'>
                                <h2>Place Name</h2>
                                <p>Explore the local water quality and historical trends in the place name.</p>
                            </div>
                        </Col>
                        <Col lg={4} className='portal' onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)}>
                            {/*<div className={`behind-portal ${isHovered3 ? 'hovered3' : ''}`}></div>*/}
                                <img src={placeholder}></img>
                            <div className='portal-content'>
                                <h2>Place Name</h2>
                                <p>Explore the local water quality and historical trends in the place name.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <br></br><br></br><br></br><br></br>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Immerse);
export { connection as Immerse };