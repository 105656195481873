//--------------------------------------------------
// Immerse Hub Footer
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import poweredLogo from '../../Images/powered-logo.png'

const ImmerseFooter = ({ }) => {
    return (
        <Fragment>
            <div className='immerse-footer'>
                <div className='row'>
                    <div className='col-6'>
                        <Link to='/immerse'>
                            <img src={poweredLogo}></img>
                        </Link>
                    </div>
                    
                    <div className='col-6'>
                        <h4>Contact Us: </h4>
                        <p>email@email.com</p>
                        <p>1801 W University Drive</p>
                        <p>Boise ID 83706</p>
                    </div>
                    
                </div>
                <div className='col-12 copyright'>
                        <p>2024 - Copyright</p>
                    </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ImmerseFooter);
export { connection as ImmerseFooter };