import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';

const libraries = ['places'];

const AddressSearchBar = () => {
  const [searchBox, setSearchBox] = React.useState(null);
  const [places, setPlaces] = React.useState([]);

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    setPlaces(places);
    console.log(places);
  };

  return (
    <LoadScript
      googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
      libraries={libraries}
    >
        <head><link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" /></head>
      <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <div className='row'>
            <div className='col-8'>
                <input
                type="text"
                placeholder="Address, Zip Code, City, etc"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: '100%',
                    height: `4vh`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    backgroundColor: 'grey',
                    color: 'black'
                }}
                />
            </div>
            <div className='col-2'>
                <button><span class="material-symbols-outlined">keyboard_return</span></button>
            </div>
        </div>

      </StandaloneSearchBox>
      <div>
        {places.map((place, index) => (
          <div key={index}>
            <strong>{place.name}</strong><br />
            {place.formatted_address}
          </div>
        ))}
      </div>
    </LoadScript>
  );
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(AddressSearchBar);
export { connection as AddressSearchBar };