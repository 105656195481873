//--------------------------------------------------
//Watershed Map Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { WatershedMap } from '../DataVisualizations/watershedMap'
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

import funLittleStar from '../../Images/fun-little-star.svg'
import redPin from '../../Images/red-pin.svg'
import bluePin from '../../Images/blue-pin.svg'

const Watershed = ({ }) => {
    return (
        <Fragment>
            <div className='watershed-header'>
                <h1>Watershed Map</h1>
            </div>
            <Row className='watershed-container justify-content-center'>
                <Col sm={3}>
                    <div className='watershed-map-key'>
                        <h1>Map Key: </h1>
                        <div className='pin'><img src={redPin}></img><h2>Water Narratives</h2></div>
                        <div className='pin'><img src={bluePin}></img><h2>Searched Address</h2></div>
                    </div>
                    <div className='unity-section'>
                        <img src={funLittleStar}></img>
                        <h3>Want to learn more about groundwater?</h3>
                        <p>Try this interactive 3d example!</p>
                        <Link to='https://gimmworks.itch.io/aquifer-dioramas' target='_blank'>
                            <button>Get Immersed</button>
                        </Link>
                    </div>
                </Col>
                <Col sm={8}>
                    <WatershedMap/>
                </Col>
            </Row>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Watershed);
export { connection as Watershed };