//--------------------------------------------------
// Coming Soon Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import jackHammer from '../../Images/jack-hammer-construction-worker.gif'

const ComingSoon = ({ }) => {
    return (
        <Fragment>
            <div className='coming-soon'>
                <br></br>
                <h1>PAGE COMING SOON</h1>
                <Link to='/'>
                    <button>Return</button>
                </Link>
                <br></br><br></br><br></br>
                <img src={jackHammer}></img>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ComingSoon);
export { connection as ComingSoon };