//--------------------------------------------------
//Root logic for client rendering down from index.js
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { alertActions } from "../Actions";
import { connect } from 'react-redux';
import { Route, Routes, BrowserRouter, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap';

import { Login, DefaultPage, Account, Home, Watershed, TVWaterAtlas, CommunityStories, GroundwaterMap, MapCollection, IrrigationDistrictsMap, Immerse, WaterOrgDirectory, Education, TheConfluenceProject, VerificationPage } from '../Components/UserPages';
import { NavigationBar, PageNotFound, SearchResults, Footer, ScrollToTop, ComingSoon, ImmerseNavigationBar, ImmerseFooter } from '../Components/GeneralComponents';

const App = ({ alert, session, clearance }) => {  

  const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [navigating, setNavigating] = useState(false);
  
    useEffect(() => {
      if (clearance === 'unverified' && window.location.pathname.toLowerCase() !== '/verification-page' && window.location.pathname.toLowerCase() !== '/water-atlas') {
        navigate('/verification-page'); 
      }
    }, [clearance, navigate]);
  
    if (clearance === 'unverified' && window.location.pathname.toLowerCase() !== '/verification-page' && window.location.pathname.toLowerCase() !== '/water-atlas') {
      return null;
    }

    return children;
  };

  //Defining multiple clearance levels of access in one variable for pages array
  const user_admin = clearance === 'admin' || clearance === 'user';
  const unverified_user_admin = clearance === 'unverified' || clearance === 'user' || clearance === 'admin';
  
  //Pages where specific clearance levels need access
  const pages = [
    { path: '/DefaultPage', component: user_admin ? <DefaultPage/> : <PageNotFound access="denied"/> },
    { path: '/Account', component: unverified_user_admin ? <Account/> : <PageNotFound access="denied"/> },
  ];

  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const handlePathChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('popstate', handlePathChange);

    const pushState = window.history.pushState;
    window.history.pushState = function () {
      pushState.apply(window.history, arguments);
      handlePathChange();
    };

    return () => {
      window.removeEventListener('popstate', handlePathChange);
      window.history.pushState = pushState;
    };
  }, []);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location]);

  const lowerCasePath = currentPath.toLowerCase();

  useEffect(() => {
    if (lowerCasePath === "/") {
      document.title = "TVWH - Home";
    } else if (lowerCasePath === "/watershed") {
      document.title = "TVWH - Watershed Map";
    } else if (lowerCasePath === "/search") {
      document.title = "TVWH - Search";
    } else if (lowerCasePath === "/voices") {
      document.title = "TVWH - Voices";
    } else if (lowerCasePath === "/immerse") {
      document.title = "Immerse Hub";
    } else if (lowerCasePath === "/data-and-maps") {
      document.title = "TVWH - Data & Maps";
    } else if (lowerCasePath === "/groundwater-map") {
      document.title = "TVWH - Groundwater Map";
    } else if (lowerCasePath === "/education") {
      document.title = "TVWH - Education";
    } else if (lowerCasePath === "/water-org-directory") {
      document.title = "TVWH - Water Org Directory";
    } else if (lowerCasePath === "/irrigation-districts") {
      document.title = "TVWH - Irrigation Districts";
    } else if (lowerCasePath === "/tcp") {
      document.title = "TVWH - TCP";
    } else if (lowerCasePath === "/verification-page") {
      document.title = "Verification";
    } else if (lowerCasePath === "/water-atlas") {
      document.title = "TV Water Atlas";
    } else if (lowerCasePath === "*") {
      document.title = "Page Not Found";
    } else {
      document.title = "TVWH";
    }
  }, [lowerCasePath]); 

  const shouldNotShowHeader = ['/water-atlas', '/verification-page'].some(path => lowerCasePath === path.toLowerCase());
  const shouldNotShowFooter = ['/water-atlas', '/login/Login', '/login/SignUp', '/login/Premium', '/groundwater-map', '/verification-page', '/search'].some(path => lowerCasePath === path.toLowerCase());
  
  return (
    <BrowserRouter>
      {shouldNotShowHeader ? <></> : lowerCasePath === '/immerse' ? <ImmerseNavigationBar/> : <NavigationBar />}
      <ScrollToTop />
      
      {/*Displays alert at the top of the screen*/}
      {alert.message ? <Alert variant={alert.type} dismissible className={`fadeAlert`}>{alert.message}</Alert> : <Fragment></Fragment>}

      {/*Displays loading screen on top of page to disable user input*/}
      {alert.loading ? <div class="loading-container"><div class="loading-text">Loading...</div></div>: <Fragment></Fragment>}

      <Routes>
        <Route path="/water-atlas" element={<TVWaterAtlas />} />

        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/login/:action" element={<ProtectedRoute><Login /></ProtectedRoute>} />
        <Route path="/watershed" element={<ProtectedRoute><Watershed /></ProtectedRoute>} />
        <Route path="/search" element={<ProtectedRoute><SearchResults /></ProtectedRoute>} />
        <Route path="/voices" element={<ProtectedRoute><CommunityStories /></ProtectedRoute>} />
        <Route path="/immerse" element={<ProtectedRoute><Immerse /></ProtectedRoute>} />
        <Route path="/data-and-maps" element={<ProtectedRoute><MapCollection /></ProtectedRoute>} />
        <Route path="/groundwater-map" element={<GroundwaterMap />} />
        <Route path="/irrigation-districts" element={<ProtectedRoute><IrrigationDistrictsMap /></ProtectedRoute>} />
        <Route path="/water-org-directory" element={<ProtectedRoute><WaterOrgDirectory /></ProtectedRoute>} />
        <Route path="/education" element={<ProtectedRoute><Education /></ProtectedRoute>} />
        <Route path="/tcp" element={<ProtectedRoute><TheConfluenceProject /></ProtectedRoute>} />

        {pages.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}

        <Route path='*' element={<PageNotFound />} />
        <Route path='0' element={<ComingSoon />} />
        <Route path='/verification-page' element={<VerificationPage />} />
      </Routes>
      {shouldNotShowFooter ? <></> : lowerCasePath === '/immerse' ? <ImmerseFooter/> : <Footer />}
    </BrowserRouter>
  )
}

function mapState(state) {
  const { alert, session } = state
  return { alert, session, clearance: state.session.clearance };
}

const actionCreators = {

};

export default connect(mapState, actionCreators)(App);