//--------------------------------------------------
//Template page to copy/paste for new pages
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions/index.js";
import * as turf from '@turf/turf';
import { connect } from 'react-redux';
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import L from 'leaflet';
import * as d3 from 'd3';
import {
    MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents, LayersControl,
    LayerGroup, FeatureGroup, GeoJSON, Tooltip, Circle, Polygon, Polyline, CircleMarker, SVGOverlay, ZoomControl
} from 'react-leaflet';
import countyData from '../../Data/counties.js';
import irrigationDistrictData from '../../Data/irrigationDistrictData.js';

//RED
const districtGroup1 = [
    "A & B IRRIGATION DISTRICT",
    "AMERICAN FALLS RESERVOIR DISTRICT #2 & BIG WOOD CANAL COMPANY",
    "AMERICAN FALLS RESERVOIR DISTRICT",
    "AVONDALE IRRIGATION DISTRICT",
    "BIG BEND IRRIGATION DISTRICT - OREGON",
    "BIG LOST RIVER IRRIGATION DISTRICT",
    "BLACK CANYON IRRIGATION DISTRICT",
    "BURLEY IRRIGATION DISTRICT",
    "NAMPA & MERIDIAN IRRIGATION DISTRICT",
    "CANYON HILL IRRIGATION DISTRICT"
];
  
//BLUE
  const districtGroup2 = [
    "CAPITOL VIEW IRRIGATION DISTRICT",
    "CASSIA CREEK IRRIGATION DISTRICT",
    "BOISE-KUNA IRRIGATION DISTRICT",
    "CENTER IRRIGATION DISTRICT",
    "COVE IRRIGATION DISTRICT",
    "CUB RIVER IRRIGATION",
    "DALTON GARDENS IRRIGATION DISTRICT",
    "EAST GREENACRES IRRIGATION DISTRICT",
    "EMMETT IRRIGATION DISTRICT",
    "ENTERPRISE IRRIGATION DISTRICT",
    "FALLS IRRIGATION DISTRICT"
];
  
//GREEN
  const districtGroup3 = [
    "FISH CREEK IRRIGATION DISTRICT",
    "FREMONT-MADISON IRRIGATION DISTRICT",
    "GEM IRRIGATION DISTRICT",
    "GOLDEN GATE IRRIGATION DISTRICT",
    "GOOSE CREEK IRRIGATION DISTRICT",
    "GRAND VIEW IRRIGATION DISTRICT",
    "HAYDEN LAKE IRRIGATION DISTRICT",
    "HILLSDALE IRRIGATION DISTRICT",
    "IDAHO IRRIGATION DISTRICT",
    "INDIAN COVE IRRIGATION DISTRICT",
    "EAGLE ISLAND WATER USERS"
];
  
//YELLOW
  const districtGroup4 = [
    "KING HILL IRRIGATION DISTRICT",
    "LAKE IRRIGATION DISTRICT",
    "LEMHI IRRIGATION DISTRICT",
    "LEWISTON ORCHARDS IRRIGATION DISTRICT",
    "LITTLE WILLOW IRRIGATION DISTRICT",
    "LITTLE WOOD RIVER IRRIGATION DISTRICT",
    "MILNER LOW LIFT IRRIGATION DISTRICT",
    "MINIDOKA IRRIGATION DISTRICT",
    "MONROE CREEK IRRIGATION DISTRICT",
    "MOUNTAIN HOME IRRIGATION DISTRICT",
    "FARMERS COOPERATIVE DITCH CO.",
    "FARMERS UNION DITCH CO."
];
  
//ORANGE
  const districtGroup5 = [
    "MURPHY IRRIGATION DISTRICT",
    "NEW SWEDEN IRRIGATION DISTRICT",
    "NEW YORK IRRIGATION DISTRICT",
    "OPALINE IRRIGATION DISTRICT",
    "PARMA IRRIGATION DISTRICT",
    "PIONEER IRRIGATION DISTRICT",
    "POPLAR IRRIGATION DISTRICT",
    "PROGRESSIVE IRRIGATION DISTRICT",
    "REYNOLDS IRRIGATION DISTRICT",
    "LITTLE PIONEER DITCH CO."
  ];
  
//PURPLE
  const districtGroup6 = [
    "RIVERSIDE IRRIGATION DISTRICT",
    "ROSEBERRY IRRIGATION DISTRICT",
    "SETTLERS IRRIGATION DISTRICT",
    "SEVEN SUCKERS IRRIGATION DISTRICT",
    "SNAKE RIVER IRRIGATION DISTRICT",
    "SNAKE RIVER VALLEY IRRIGATION DISTRICT",
    "SOUTHWEST IRRIGATION DISTRICT",
    "SQUAW CREEK IRRIGATION DISTRICT",
    "STRONGARM IRRIGATION DISTRICT",
    "SUNNYDELL IRRIGATION DISTRICT",
    "PRIVATE BOISE RIVER TRIBUTARY DIVERSIONS"
  ];
  
  //CYAN
  const districtGroup7 = [
    "TRIANGLE IRRIGATION DISTRICT (partitioned from Wood River Valley Irr. Dist. #45)",
    "TRINITY MOUNTAIN IRRIGATION DISTRICT",
    "WEISER COVE IRRIGATION DISTRICT",
    "WEISER IRRIGATION DISTRICT",
    "WEST REYNOLDS IRRIGATION DISTRICT",
    "WILDER IRRIGATION DISTRICT",
    "WOOD RIVER VALLEY IRRIGATION DISTRICT #45"
  ];

/* component that creates a polygon on the map based on geoJSON data */
const GeoJSONFormat = ({ data, style }) => {
    const map = useMap();

    const onEachFeature = (feature, layer) => {
        if (feature.properties && feature.properties.NAME) {
            layer.bindTooltip(feature.properties.NAME, {
                sticky: true,
                direction: 'auto',
                className: 'custom-tooltip'
            });
        }

        if (feature.properties && feature.properties.IDIST) {
            layer.bindTooltip(feature.properties.IDIST, {
                sticky: true,
                direction: 'auto',
                className: 'custom-tooltip'
            });
        }
    }

    const handleStateClick = (e) => {
        let stateFeature;

        if (e.layer) {
            stateFeature = e.layer.feature;
        } else if (e.target) {
            stateFeature = e.target.feature;
        }

        if (stateFeature) {
            const bounds = L.geoJSON(stateFeature).getBounds();
            const center = bounds.getCenter();
            map.flyTo(center, 10);
        }
    };

    return (
        <GeoJSON data={data} style={style} eventHandlers={{ click: handleStateClick }} onEachFeature={onEachFeature} />
    );
};

/* map search bar component. lets you search addresses */
const SearchField = (props) => {
    const map = useMap();

    useEffect(() => {
        const searchControl = new GeoSearchControl({
            provider: props.provider,
            style: "bar",
            marker: {
                icon: new L.icon({
                    iconUrl:
                        "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
                    iconSize: [25, 41],
                    iconAnchor: [10, 41],
                    popupAnchor: [2, -40]
                })
            },
            ...props
        });

        map.addControl(searchControl);
        // Append GeoSearch control to findbox
        /*if (props.findboxRef.current) {
        props.findboxRef.current.appendChild(document.querySelector('.geosearch'));
      }*/

        
        return () => map.removeControl(searchControl);
    }, [map, props]);

    return null;
};

const OutsideSearch = ({ searchQuery, geoJSONData, onIDIST }) => {
    const map = useMap();
    const markerRef = useRef(null);
  
    useEffect(() => {
      if (!searchQuery) return;
  
      const provider = new OpenStreetMapProvider();
  
      const handleShowLocation = (result) => {
        // Add your custom event handling logic here
      };
  
      map.on('geosearch/showlocation', handleShowLocation);
  
      const searchLocation = async () => {
        const results = await provider.search({ query: searchQuery });

        console.log("Results: ", results);
  
        if (results && results.length > 0) {
          const { x, y, label } = results[0];
  
          map.setView([y, x], 13);
            const markerIcon = L.icon({
                iconUrl: require('../../Images/map-marker-icon.png'),
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32],
                shadowUrl: null,
                shadowSize: null,
                shadowAnchor: null
            });

            // Remove the previous marker if it exists
        if (markerRef.current) {
            map.removeLayer(markerRef.current);
          }

            const marker = L.marker([y, x], { icon: markerIcon }).addTo(map)
            .bindPopup(label)
            .openPopup();

            markerRef.current = marker;

            // Check if the marker is inside any polygon
            const point = turf.point([x, y]);

            const containingFeature = geoJSONData.features.find(feature => {
                const polygon = turf.polygon(feature.geometry.coordinates);
                return turf.booleanPointInPolygon(point, polygon);
            });

            if (containingFeature) {
                const idist = containingFeature.properties.IDIST;
                onIDIST(idist);
            } else {
                onIDIST(null);
            }
  
          map.fire('geosearch/showlocation', { location: { lat: y, lng: x }, label });
        }
      };

      searchLocation();
  
      return () => {
        map.off('geosearch/showlocation', handleShowLocation);
        if (markerRef.current) {
            map.removeLayer(markerRef.current);
            markerRef.current = null;
        }
      };
    }, [searchQuery, map, geoJSONData, onIDIST]);
  
    return null;
  };

/*water map*/
const IrrigationMap = ({ useAllIDCountyOutlines, useIrrigationDistricts, showSearchField, mapX, mapY, zoomLevel, searchQuery, onIDIST }) => {
    //state variables that determine what is available on map
    //const [isNewWellDataLayerVisible, setNewWellDataLayerVisibility] = useState(useGroundWaterLevel);
    //const [isCountiesLayerVisible, setCountiesLayerVisibility] = useState(useTVCountyOutlines);
    //const [isTooltipPermanent, setTooltipPermanency] = useState(false);

    //map variables
    const center = [mapX, mapY];
    const bounds = [
        [center[0] - 1.0, center[1] - 1.0], // Southwest corner
        [center[0] + 1.0, center[1] + 1.0]  // Northeast corner
    ];
    
    // Validate and log coordinates
const validateCoordinates = (coords) => {
    if (!coords || coords.length < 2 || typeof coords[0] !== 'number' || typeof coords[1] !== 'number') {
      console.error('Invalid coordinates', coords);
      return false;
    }
    return true;
  };


    //the icon used when placing a marker and for NewWellData
    const markerIcon = L.icon({
        iconUrl: require('../../Images/map-marker-icon.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    //the icon used for a well that has a trend of decreasing water levels
   /* const decreasingMarkerIcon = L.icon({
        iconUrl: require('../../Images/TEST-1024px-Eo_circle_red_arrow-down.svg.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    //the icon used for a well that has a trend of increasing water levels
    const increasingMarkerIcon = L.icon({
        iconUrl: require('../../Images/TEST-Eo_circle_green_arrow-down.svg.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });*/

    const pointToLayer = (feature, latlng) => {
        return L.circleMarker(latlng, {
            radius: 8,
            fillColor: 'blue',
            color: 'blue',
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
        });
    };

    const prov = new OpenStreetMapProvider(); //map provider. used in the SearchField component 

    const getStyle = (feature) => {
        const idist = feature.properties.IDIST;
            if (districtGroup1.includes(idist)) {
                return {
                    color: "red",
                    weight: 2,
                    fillColor: "red",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup2.includes(idist)) {                
                return {
                    color: "blue",
                    weight: 2,
                    fillColor: "blue",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup3.includes(idist)) {                
                return {
                    color: "green",
                    weight: 2,
                    fillColor: "green",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup4.includes(idist)) {                
                return {
                    color: "yellow",
                    weight: 2,
                    fillColor: "yellow",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup5.includes(idist)) {          
                return {
                    color: "orange",
                    weight: 2,
                    fillColor: "orange",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup6.includes(idist)) {        
                return {
                    color: "purple",
                    weight: 2,
                    fillColor: "purple",
                    fillOpacity: 0.3
                };
            }
            else if (districtGroup7.includes(idist)) {
                return {
                    color: "cyan",
                    weight: 2,
                    fillColor: "cyan",
                    fillOpacity: 0.3
                };
            }
            else {
                return {
                    color: "gray",
                    weight: 2,
                    fillColor: "lightgray",
                    fillOpacity: 0.3
                };
            }
    }    

    //a style for outlining and filling a given area, such as the counties, lakes, drawn polygons, etc.
    const geoJSONWaterStyle = {
        color: "blue",
        weight: 2,
        fillColor: "lightblue",
        fillOpacity: 0.5
    };

    //a style for outlining and filling the counties that make up the treasure valley 
    const geoJSONTVStyle = {
        color: "green",
        weight: 2,
        fillColor: "greenyellow",
        fillOpacity: 0.5
    };

    //adds a tooltip on hover with all the information about the well
    const onEachWell = (feature, layer) => {
        const contentValues = [];

        if (feature.properties) {
        for (const key in feature.properties) {
            if (feature.properties.hasOwnProperty(key)) {
                if(feature.properties[key] !== null && feature.properties[key] !== ""){
                    contentValues.push(feature.properties[key]);
                } else if (feature.properties[key] == null || feature.properties[key] == ""){
                    contentValues.push("not collected");
                }

            }
        }

        const tooltipContent = `
            <div>
              <strong>Bore:</strong> ${contentValues[0]}<br/>
              <strong>Easting:</strong> ${contentValues[2]}<br/>
              <strong>Northing:</strong> ${contentValues[3]}<br/>
              <strong>Total Depth:</strong> ${contentValues[4]}<br/>
              <strong>Well ID:</strong> ${contentValues[6]}<br/>
              <strong>Click the marker for additional information</strong>
            </div>
          `;

        const popupContent = `
            <div>
              <strong>Bore:</strong> ${contentValues[0]}<br/>
              <strong>Well Type:</strong> ${contentValues[1]}<br/>
              <strong>Easting:</strong> ${contentValues[2]}<br/>
              <strong>Northing:</strong> ${contentValues[3]}<br/>
              <strong>Total Depth:</strong> ${contentValues[4]}<br/>
              <strong>Completion Date:</strong> ${contentValues[5]}<br/>
              <strong>Well ID:</strong> ${contentValues[6]}<br/>
              <strong>Metal Tag No:</strong> ${contentValues[7]}<br/>
              <strong>PLSS ID:</strong> ${contentValues[8]}<br/>
              <strong>Log URL:</strong> <a href="${contentValues[10]}" target="_blank">Log Link</a>
            </div>
          `;

            layer.bindTooltip(tooltipContent, {
                permanent: false,
                direction: 'top',
                offset: L.point(0, -20)
            });

            layer.bindPopup(popupContent, {
                maxWidth: 200,
                closeButton: true
            });
        }
    };

    return (
        <Fragment>
            <div className='basic-map'>
                <MapContainer center={center} zoom={zoomLevel} maxBounds={bounds} maxBoundsViscosity={1.0} minZoom={8}>
                    <TileLayer
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    />

                    {showSearchField && 
                        <SearchField
                            provider={prov}
                            showMarker={true}
                            showPopup={true}
                            popupFormat={({ query, result }) => result.label}
                            maxMarkers={3}
                            retainZoomLevel={false}
                            animateZoom={true}
                            autoClose={false}
                            searchLabel={"Enter address"}
                            keepResult={true}
                        />
                    }

                    <LayersControl position="topleft">

                        {useIrrigationDistricts && 
                            <LayersControl.Overlay name="Irrigation Districts" checked>
                                <GeoJSONFormat data={irrigationDistrictData} style={getStyle}/>
                            </LayersControl.Overlay>
                        }

                        {useAllIDCountyOutlines &&
                            <LayersControl.Overlay checked name="All Idaho Counties">
                                <GeoJSONFormat data={countyData} style={geoJSONWaterStyle} />
                            </LayersControl.Overlay>
                        }

                    </LayersControl>
                    <OutsideSearch searchQuery={searchQuery} geoJSONData={irrigationDistrictData} onIDIST={onIDIST} />
                </MapContainer>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(IrrigationMap);
export { connection as IrrigationMap };