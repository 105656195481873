//--------------------------------------------------
// Page Footer
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import poweredLogo from '../../Images/powered-logo.png'

const Footer = ({ }) => {
    return (
        <Fragment>
            <Container fluid className='footer'>
                <Row className='justify-content-center'>
                    <Col sm={4} className='footer-logo'>
                        <Link to='/immerse' target='_blank'>
                            <img src={poweredLogo}></img>
                        </Link>
                    </Col>

                    <Col sm={4} className='footer-tabs'>
                        <Link to='/data-and-maps'>
                            <h3>Data & Maps</h3>
                        </Link>
                        <Link to='/education'>
                            <h3>Education</h3>
                        </Link>
                        <Link to='0'>
                            <h3>Tools</h3>
                        </Link>
                    </Col>

                    <Col sm={4} className='footer-contact-us'>
                        <h4>Contact Us: </h4>
                        <p>email@email.com</p>
                        <p>1801 W University Drive</p>
                        <p>Boise ID 83706</p>
                    </Col>
                </Row>
                <p className='copyright'>2024 - Copyright</p>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Footer);
export { connection as Footer };