//--------------------------------------------------
//Redux reducer for create, read, update, delete actions
//Should be extended into different files for large projects
//--------------------------------------------------

import { crudConstants } from "./constants";

const INITIALSTATE = {
};

export function crud(state = INITIALSTATE, action) {
    switch (action.type) {
        default:
            return state;
    }
}