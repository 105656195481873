//--------------------------------------------------
//Education Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Container, Row, Col } from 'react-bootstrap';

import leftArrow from '../../Images/left-arrow.svg'
import rightArrow from '../../Images/right-arrow.svg'

import TVWA from '../../Images/tvWaterAtlasLogo.png'
import TCP from '../../Images/TCP1.png'
import watershed from '../../Images/watershedMap.PNG'
import floatTheBoise from '../../Images/floatTheBoise.png'
import whitewaterPark from '../../Images/whitewaterPark.png'
import smallLogo from '../../Images/smallLogo.png'

const videos = [
    { name: 'What Are Canals and Laterals?', credits: 'Treasure Valley Water Users Association', video: 'https://www.youtube.com/embed/dVQpE11dsPs?si=1o2vEcrP0T4GB2mq', hasSpanish: false },
    { name: 'Irrigation Storage Delivery', credits: 'Treasure Valley Water Users Association', video: 'https://www.youtube.com/embed/UAVk6ZUsUv4?si=PlQpoHmimEz9gzPy', hasSpanish: false },
    { name: 'Dumping Into Canals', credits: 'Treasure Valley Water Users Association', video: 'https://www.youtube.com/embed/_ksBxRvqQi8?si=1TkUBvD1h5ckJWEF', hasSpanish: false },
    { name: 'Idaho Private Water Well Testing', credits: 'Idaho Department of Health & Wellness', video: 'https://www.youtube.com/embed/hpthT8HK7nY?si=3CFCuFyDwa0bxV7O', hasSpanish: true, spanishName: 'Pruebas de Agua de Pozos Privados', spanishVideo: 'https://www.youtube.com/embed/-0eBHJ0xDWE?si=FNGxBvMnpnCbbOdX' },
    { name: 'Idaho Lead and Copper Water Sampling', credits: 'Idaho Department of Health & Wellness', video: 'https://www.youtube.com/embed/I5rHtdMvxBM?si=quqty14WsQA1rP_1', hasSpanish: true, spanishName: 'Pruebas de Plomo y Cobre en el Agua', spanishVideo: 'https://www.youtube.com/embed/s0YskTAhq-Y?si=OU_y15iihv4WjsCK'  },
    { name: 'The Boise River: From Snow to You', credits: 'City of Boise', video: 'https://www.youtube.com/embed/df2PPs2cLXk?si=uPM9hYKBODg1h-79', hasSpanish: false },
    { name: 'Overview', credits: 'City of Boise', video: 'https://www.youtube.com/embed/KOHyBjAlBME?si=fpaGkDbews3Ln9FB', hasSpanish: false },
    { name: 'Rainwater Catchment', credits: 'Ada Soil & Water Conservation District', video: 'https://www.youtube.com/embed/V7W2YiNnxKY?si=rqAw0PdHLP2QhwJo', hasSpanish: false },
    { name: 'Water Conservation for Lawns & Gardens', credits: 'Ada Soil & Water Conservation District', video: 'https://www.youtube.com/embed/1J5prxF0P7M?si=80XnVLng6h1rCD3Z', hasSpanish: false },
    { name: 'Water Conservation for Pastures', credits: 'Ada Soil & Water Conservation District', video: 'https://www.youtube.com/embed/wgsiV4ItuNU?si=lfMQTk0fT_srUeQT', hasSpanish: false },
    { name: 'Irrigation Land Leveling', credits: 'USDA NRCS', video: 'https://www.youtube.com/embed/aE9Y4x0wL7U?si=YcT-_zwSPtftUKXo', hasSpanish: false },
    { name: 'Irrigation System, Surface', credits: 'USDA NRCS', video: 'https://www.youtube.com/embed/i4PgWEjTgXM?si=5PpqXsuNRwK1SQJV', hasSpanish: false },
    { name: 'Open Channel', credits: 'USDA NRCS', video: 'https://www.youtube.com/embed/Y-Hv-5HNyDs?si=Qlnk0oZiYYyGWbAV', hasSpanish: false },
    { name: 'Boises Geothermal Heating Utility', credits: 'City of Boise', video: 'https://www.youtube.com/embed/5pUpP57Zp18?si=MDuOFRDolpW8iqBs', hasSpanish: false },
];

const educationLinks = [
    { header: 'The Confluence Project (TCP)', content: 'Place-Based High School Field Experiences and Youth Water Summit.', img: TCP, path: '/tcp', offsite: false },
    { header: 'Learn about Idaho Watersheds', content: 'Explore story maps from across the basin.', img: watershed, path: '/watershed', offsite: false },
    { header: 'Be Safe. Have Fun. Float the Boise!', content: 'Find current and up-to date information you need about safely floating the boise river this summer!', img: floatTheBoise, path: 'https://www.floattheboise.org/', offsite: true },
    { header: 'Boise Whitewater Park', content: "Looking for a whitewater adventure in your own backyard? Look no further than Boise’s Whitewater Park!", img: whitewaterPark, path: 'https://www.boisewhitewaterpark.com/waveshaper-cam', offsite: true },
];

const Education = ({ }) => {
    const [spanishStates, setSpanishStates] = useState(
        videos.reduce((acc, video) => {
          acc[video.id] = false;
          return acc;
        }, {})
      );

    const toggleLanguage = (id) => {
        setSpanishStates(prevStates => ({
          ...prevStates,
          [id]: !prevStates[id]
        }));
    };

    return (
        <Fragment>
            <Container fluid className='education-container'>
                <h1>Water Education</h1>
                <div className='education-top-section'>
                    <div className='top-section-container'>
                        <Link to='/waterAtlas' target='_blank'>
                            <div className='img-section' style={{background: '#B2D9FF'}}>
                                <img src={TVWA}></img>
                            </div>
                        </Link>
                        <br/>
                        <div className='content-section'>
                            <h3>Treasure Valley Water Atlas</h3>
                            <p>The Treasure Valley Water Atlas is a resource for decision-makers, educators, and water-users who care and wish to know more about water in the Treasure Valley. You can explore the source and history of our water, read about water law and management, learn about impacts to the water we use, and learn how to take action.</p>
                            <Link to='/waterAtlas' target='_blank'>
                                <span>Learn More &gt;</span>
                            </Link>
                        </div>
                    </div>
                    <div className='top-section-container'>
                        <Link to='https://gimmworks.itch.io/aquifer-dioramas' target='_blank'>
                            <div className='img-section' style={{background: '#FFFFFF'}}>
                                <img src={smallLogo}></img>
                            </div>
                        </Link>
                        <br/>
                        <div className='content-section'>
                            <h3 className='education-special'>Learn More About Groundwater</h3>
                            <p>Try this interactive 3D diorama!</p>
                            <Link to='https://gimmworks.itch.io/aquifer-dioramas' target='_blank'>
                                <span>Get Immersed &gt;</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div className='video-blurb'>
                    <h4>Explore these educational videos from local organizations!</h4>
                </div>
                <br/>
                <div className='community-quick-access'>
                    <button className='prev-arrow'>
                        <img src={leftArrow}></img>
                    </button>
                    <Swiper
                        navigation={{ nextEl: '.next-arrow', prevEl: '.prev-arrow' }}
                        slidesPerView={3}
                        setWrapperSize={false}
                        spaceBetween={10}
                        modules={[Navigation]}
                        className="mapSwiper"
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 35,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 10,
                            },
                          }}
                    >
                        {videos.map((video, index) => ( 
                            <SwiperSlide>
                                <div className='article-tab' key={index}>
                                    <iframe src={spanishStates[index] ? video.spanishVideo : video.video} allowFullScreen></iframe>
                                    <h3>{spanishStates[index] ? video.spanishName : video.name}</h3>
                                    <p>By: {video.credits}</p>
                                    {video.hasSpanish && (
                                        <div>
                                            <button onClick={() => toggleLanguage(index)}>
                                                {spanishStates[index] ? 'Show in English' : 'Show in Spanish'}
                                            </button>
                                            <br/><br/>
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button className='next-arrow'>
                        <img src={rightArrow}></img>
                    </button>
                </div>
                <br/>
                <div className={'community-article-section down'}>
                    <br/>
                    {educationLinks.map((article, index) => (
                        <Container fluid className={'large-article down'} key={index}>
                            <Row className='justify-content-center'>
                            {index % 2 === 0 ? (
                                <>
                                <Col xs={5}>
                                    <Link to={article.path} target={article.offsite ? '_blank' : ''}>
                                        <img src={article.img} alt="article image" />
                                    </Link>
                                </Col>
                                <Col xs={6} className='article-text'>
                                    <div>
                                    <h2>{article.header}</h2>
                                    <p>{article.content}</p>
                                    <Link to={article.path} target={article.offsite ? '_blank' : ''}>
                                        <span>Learn more &gt;</span>
                                    </Link>
                                    </div>
                                </Col>
                                </>
                            ) : (
                                <>
                                <Col xs={6} className='article-text'>
                                    <div>
                                    <h2>{article.header}</h2>
                                    <p>{article.content}</p>
                                    <Link to={article.path} target={article.offsite ? '_blank' : ''}>
                                        <span>Learn more &gt;</span>
                                    </Link>
                                    </div>
                                </Col>
                                <Col xs={5}>
                                    <Link to={article.path} target={article.offsite ? '_blank' : ''}>
                                        <img src={article.img} alt="article image" />
                                    </Link>
                                </Col>
                                </>
                            )}
                            </Row>
                        </Container>
                    ))}
                </div>
                <br/>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Education);
export { connection as Education };