
//--------------------------------------------------
//Generic drag/drop list
//--------------------------------------------------

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React from 'react';

//Array is object to sort
//OnListReorder is callback. returns reordered array
//Prop is what to sort by in object

const DragDrop = ({ array, onListReorder, prop}) => {
    let items = [];
    
    //Object to array
    array.forEach(element => {
      items.push(element[prop])
    });

    //Runs when the item is dropped
    const handleDragEnd = (result) => {
      if (!result.destination) return;
      //Reorders the original array
      const newItems = Array.from(items);
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);
      const reordered = [];
      newItems.forEach((title) => {
        const item = Object.values(array).find((obj) => obj[prop] === title);
        if (item) {
            reordered.push(item);
        }
      });

      //Runs callback
      onListReorder(reordered);
    };
  
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: 'white',
                width: '100%',
                borderRadius: '5px',
                minHeight: '50px'
              }}
              className=' d-flex flex-wrap'
            >
              {array.map((item, index) => (
                <Draggable key={item[prop]} draggableId={item[prop]} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      userSelect: 'none',
                      padding: 8,
                      width: '100%',
                      height: 'fit-content',
                      borderRadius: "5px",
                      margin: '0 0 .3rem 0',
                      background: snapshot.isDragging ? 'lightgreen' : '#f0f0f0',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <span {...provided.dragHandleProps}>
                        <h6 className="drag-title">{item[prop]}</h6>
                    </span>
                  </div>
                )}
              </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

export default DragDrop;