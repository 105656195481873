import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import * as d3 from "d3";
import { animated, SpringValue, useSpring } from "react-spring";
import { UseDimensions } from './useDimensions.js';
import { Tooltip } from './tooltip.js';

// Define margins for the graph
const MARGIN = { top: 30, right: 30, bottom: 50, left: 70 };

// Define colors for different lines in the graph
const colors = [
    "#E9EFFF", //dot (white blue)
    "#e85252", //??
    "#6689c6", //??
    "#9a6fb0", //??
    "#a53253", //??
    "#69b3a2", //??
];

// LineGraph component
const LineGraph = ({ data, selectedGroup, targetRef, isTimeScale, xAxisLabel, yAxisLabel, tooltipTitle }) => {
    // Use the useDimensions hook to get dimensions of the target element
    const { width, height } = UseDimensions(targetRef);
    const [adjustedHeight, setAdjustedHeight] = useState(height);

    //interaction data for tooltip
    const [interactionData, setInteractionData] = useState();

    // State to track cursor position
    const [cursorPosition, setCursorPosition] = useState(null);

    // Ref for axes
    const axesRef = useRef(null);

    useEffect(() => {
        if (height > 300) {
            setAdjustedHeight(300);
        } else {
            setAdjustedHeight(height);
        }
    }, [height]);



    // Calculate bounds width and height. bounds = area inside the graph axis = calculated by substracting the margins
    const boundsWidth = useMemo(() => width - MARGIN.right - MARGIN.left, [width]);
    const boundsHeight = useMemo(() => adjustedHeight - MARGIN.top - MARGIN.bottom, [adjustedHeight]);

    // Define linear scales for x axes. if isTimeScale is true, then use d3.scaleTime() if not, then use d3.scaleLinear()
    const xScale = useMemo(() => {
        if (isTimeScale) {
            return d3.scaleTime().domain(d3.extent(data, d => d.x)).range([0, boundsWidth]);
        } else {
            return d3.scaleLinear().domain([0, 10]).range([0, boundsWidth]);
        }
    }, [data, width, boundsWidth, isTimeScale]);

    // Define linear scales for y axes
    const yScale = useMemo(() => {
        return d3.scaleLinear().domain([d3.min(data, d => d[selectedGroup]), d3.max(data, d => d[selectedGroup])]).nice().range([boundsHeight, 0]);
    }, [data, height, boundsHeight]);

    // Render the X and Y axis using d3.js, not react
    useEffect(() => {
        const svgElement = d3.select(axesRef.current);
        svgElement.selectAll("*").remove();

        //change how xAxis is generated depending on if the isTimeScale is true or not. if it is, then the X values are dates and/or times
        if (isTimeScale) {
            const xAxisGenerator = d3.axisBottom(xScale).tickFormat(d3.timeFormat("%Y"));
            svgElement
                .append("g")
                .attr("transform", "translate(0," + boundsHeight + ")")
                .style("color", "#C5CBE2") // Axis color (pale blue)
                .call(xAxisGenerator);
        } else {
            const xAxisGenerator = d3.axisBottom(xScale);
            svgElement
                .append("g")
                .attr("transform", "translate(0," + boundsHeight + ")")
                .style("color", "#C5CBE2") // Axis Text color (pale blue)
                .call(xAxisGenerator);
        }

        //genrate yAxis
        const yAxisGenerator = d3.axisLeft(yScale);
        svgElement.append("g")
            .style("color", "#C5CBE2") // Axis color (pale blue)
            .call(yAxisGenerator);

        // Add X Axis label
        svgElement.append("text")
            .attr("className", "x-axis-label")
            .attr("text-anchor", "middle")
            .attr("x", boundsWidth / 2)
            .attr("y", boundsHeight + 30)
            .text(xAxisLabel)
            .style("font-size", "1em")
            .style("font-weight", "bold")
            .style("font-family", "'Montserrat', sans-serif")
            .style("fill", "#234373"); // Text color (dark blue)

        // Add Y-axis label with line breaks
        const yAxisLabelLines = yAxisLabel.split('\n'); // Split on newline character
        const yAxisLabelText = svgElement.append("text")
            .attr("className", "y-axis-label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -boundsHeight / 2 + 5)
            .attr("y", -MARGIN.left + 15);


        yAxisLabelLines.forEach((line, i) => {
            yAxisLabelText.append('tspan')
                .attr('x', -70)
                .attr('dy', i === 0 ? 0 : '1.2em') // Adjust line spacing as needed
                .text(line)
                .style("font-size", ".9em")
                .style("font-weight", "bold")
                .style("font-family", "'Montserrat', sans-serif")
                .style("fill", "#234373"); // Text color (dark blue)
        });

    }, [xScale, yScale, boundsHeight, data]);

    // Create line function using d3.js
    const lineBuilder = useMemo(
        () => d3.line().x((d) => xScale(d.x)).y((d) => yScale(d[selectedGroup])),
        [xScale, yScale, selectedGroup]
    );
    const linePath = useMemo(() => lineBuilder(data), [lineBuilder, data]);

    if (!linePath) {
        console.log("linepath returned false.");
        return null;
    }

    // Handle mouse enter event. sets the cursor position and interactiondata
    const handleMouseEnter = (event, xAxisLabel, yAxisLabel, lineValueX, lineValueY, barColor) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;  // This is the cursor pixel position relative to the SVG element.

        const closest = getClosestPoint(mouseX);  // Find the closest data point to the mouseX position.
        setCursorPosition(xScale(closest.x));  // Set the cursor position to the corresponding scaled value.

        const { offsetX, offsetY } = event.nativeEvent;
        setInteractionData({ xPos: offsetX , yPos: offsetY - 40, nameLabel: "Date: ", name: lineValueX.toString(), value1Label: "Depth: ", value: lineValueY, color: barColor, title: tooltipTitle, width: boundsWidth, height: boundsHeight });
    };
    //Event handler for mouse leave. sets cursorPosition and interactionData to null once the mouse leaves the svg
    const handleMouseLeave = () => {
        setInteractionData(null);
        setCursorPosition(null)
    };

    // Find the closest data point to the cursor position
    const getClosestPoint = (cursorPixelPosition) => {
        const x = xScale.invert(cursorPixelPosition);

        let minDistance = Infinity;
        let closest = null;
        for (const point of data) {
            const distance = Math.abs(point.x - x);
            if (distance < minDistance) {
                minDistance = distance;
                closest = point;
            }
        }

        return closest;
    };

    //get the keys other than x
    const keys = Object.keys(data[0]).filter(key => key !== 'x');

    //assigns each line a color
    const colorScale = d3.scaleOrdinal().domain(keys).range(colors);

    //put the line graph together using all the components 
    return (
        <div ref={targetRef} className="svg-container line-container">
            <svg width={width > 200 ? width : 200} height={adjustedHeight}>
                {/* first group is lines */}
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${MARGIN.left},${MARGIN.top})`}
                >
                    <LineItem
                        path={linePath}
                        color={colorScale(selectedGroup)}
                    />
                    {cursorPosition && (
                        <Cursor
                            height={boundsHeight}
                            x={cursorPosition}
                            y={yScale(getClosestPoint(cursorPosition)?.[selectedGroup])}
                            color={colorScale(selectedGroup)}
                        />
                    )}
                    {/* Invisible rectangle to capture mouse events */}
                    <rect
                        x={0}
                        y={0}
                        width={Math.abs(boundsWidth)}
                        height={Math.abs(boundsHeight)}
                        onMouseMove={(event) => handleMouseEnter(event, xAxisLabel, yAxisLabel, getClosestPoint(cursorPosition).x, getClosestPoint(cursorPosition)?.[selectedGroup], colorScale(selectedGroup), selectedGroup)}
                        onMouseLeave={handleMouseLeave}
                        visibility={"hidden"}
                        pointerEvents={"all"}
                    />
                </g>
                {/* Second group is for the axes */}
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    ref={axesRef}
                    transform={`translate(${MARGIN.left},${MARGIN.top})`}
                />
            </svg>
            {/* Tooltip container */}
            <div className='tooltip-container'>
                <Tooltip interactionData={interactionData}></Tooltip>
            </div>
        </div>
    );
};

//LineItem component
const LineItem = ({ path, color }) => {
    const lineRef = useRef(null);

    useEffect(() => {
        if (lineRef.current) {
            // Select the line path and bind data using a key function
            d3.select(lineRef.current)
                .datum(path) // Update the data bound to the line path
                .transition() // Start a transition
                .duration(1000) // Set the transition duration
                .attr('d', d => d) // Update the path attribute
                .attr('stroke', '#E9EFFF'); // Update the stroke color of the graph (white blue)
        }
    }, [path, color]);

    return (
        <path ref={lineRef} fill="none" strokeWidth={2} />
    );
};
// Cursor component
const Cursor = ({ height, x, y, color }) => {
    // Animation for cursor
    const springProps = useSpring({
        to: async (next, cancel) => {
            await next({ x, y })
        },
    });

    if (!springProps.x) {
        return null;
    }

    return (
        <>
            {/*animated line to follow the mouse position*/}
            <animated.line
                x1={springProps.x}
                x2={springProps.x}
                y1={0}
                y2={height}
                stroke="#234373" //line (dark blue)
                strokeWidth={1}
            />{/**/}
            {/*animated circle to follow the mouse position along the line path*/}
            <animated.circle cx={springProps.x} cy={springProps.y} r={5} fill={color} />
        </>
    );
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(LineGraph);
export { connection as LineGraph };