//--------------------------------------------------
//Bar Graph
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import * as d3 from "d3";
import { useSpring, animated } from "react-spring";
import { UseDimensions } from './useDimensions.js';
import { Tooltip } from './tooltip.js';

const BAR_PADDING = 0.2;
const MARGIN = { top: 0, right: 30, bottom: 40, left: 40 };

const BarGraph = ({ data, targetRef }) => {

    useEffect(() => {
        const svg = d3.select(targetRef.current);
        const width = targetRef.current.clientWidth;
        const height = targetRef.current.clientHeight;
        const innerWidth = width - MARGIN.left - MARGIN.right;
        const innerHeight = height - MARGIN.top - MARGIN.bottom;

        svg.selectAll("*").remove(); // Clear previous content

        // Set up scales
        const xScale = d3.scaleBand()
            .domain(data.map(d => d.years))
            .range([0, innerWidth])
            .padding(BAR_PADDING);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.totalAmount)])
            .nice()
            .range([innerHeight, 0]);

        // Set up the main group element
        const g = svg.append("g")
            .attr("transform", `translate(${MARGIN.left},${MARGIN.top})`);

        // Set up axes
        g.append("g")
            .call(d3.axisLeft(yScale))
            .append("text")
            .attr("x", -MARGIN.left)
            .attr("y", -10)
            .attr("text-anchor", "start")
            .attr("fill", "black")
            .text("Total Amount");

        g.append("g")
            .attr("transform", `translate(0,${innerHeight})`)
            .call(d3.axisBottom(xScale))
            .append("text")
            .attr("x", innerWidth)
            .attr("y", 30)
            .attr("text-anchor", "end")
            .attr("fill", "black")
            .text("Years");

        // Create bars
        g.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", d => xScale(d.years))
            .attr("y", d => yScale(d.totalAmount))
            .attr("width", xScale.bandwidth())
            .attr("height", d => innerHeight - yScale(d.totalAmount))
            .attr("fill", "steelblue");
    }, [data, targetRef]);

    return (
        <svg ref={targetRef} width="100%" height="500" style={{ border: '1px solid black' }}></svg>
    );
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(BarGraph);
export { connection as BarGraph };