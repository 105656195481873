//--------------------------------------------------
//Navbar for the application
//--------------------------------------------------

import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginModal } from '../GeneralComponents/LoginModal';

import { Container, Nav, Navbar, NavDropdown, Offcanvas, Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { userActions } from '../../Actions';

import logo from '../../Images/logo.png';
import navbarSearch from '../../Images/navbar-search.svg';
import navbarSearch2 from '../../Images/navbar-search2.svg';
import navbarLogin from '../../Images/navbar-login.svg';
import navbarLogin2 from '../../Images/navbar-login2.svg';
import searchButton from '../../Images/search-button.svg';


const NavigationBar = ({ session, Logout }) => {
    const [showSearch, setShowSearch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const searchButtonRef = useRef(null);
    const navigate = useNavigate();
    const { clearance } = session;
    const [isGroundwaterMap, setIsGroundwaterMap] = useState(false);
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const [offCanvasClassName, setOffCanvasClassName] = useState('');

    const handleToggle = () => {
        setIsOffcanvasOpen(!isOffcanvasOpen);
        setShowSearch(false);
    }
    const handleClose = () => {
        setIsOffcanvasOpen(false);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 992 && isOffcanvasOpen) {
                handleClose();
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOffcanvasOpen]);

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowSearch(false);
        navigate(`/search?q=${searchQuery}`);
        setSearchQuery();
    };

    const toggleSearchDropdown = () => {
        setShowSearch(!showSearch);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            searchButtonRef.current &&
            !searchButtonRef.current.contains(event.target)
        ) {
            setShowSearch(false);
        }
    };

    const handleModal = () => {
        setShowModal(!showModal);
    };

    useEffect(() => {
        if (showSearch) {
            document.addEventListener('mousedown', handleClickOutside);
            document.getElementById('search').focus();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSearch]);

    if (isGroundwaterMap === false) {
        return (
            <Fragment>
                {['lg'].map((expand) => (
                    <Navbar key={expand} expand={expand} className="nav-bg" sticky="top">
                        <Container fluid>
                            <Navbar.Brand href="/"><img src={logo} />{' '}</Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleToggle} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                                backdrop={false}
                                show={isOffcanvasOpen}
                                onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        Treasure Valley Water Hub
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1">
                                        <Nav.Link className='word-link' href="/data-and-maps">Data & Maps</Nav.Link>
                                        <Nav.Link className='word-link' href="/education">Education</Nav.Link>
                                        <Dropdown className='word-link-dropdown-container'>
                                            <Dropdown.Toggle className='word-link-dropdown'>Tools</Dropdown.Toggle>
                                            <Dropdown.Menu className='word-link-menu'>
                                                <Dropdown.Item href="0">For Policy Makers</Dropdown.Item>
                                                <Dropdown.Item href="0">For Managers</Dropdown.Item>
                                                <Dropdown.Item href="0">For Community</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {isOffcanvasOpen ?
                                            (
                                                <>
                                                    <Nav.Link className='word-link' onClick={handleModal}>Account</Nav.Link>
                                                    <Form ref={dropdownRef} inline onSubmit={handleSubmit} className='search-dropdown-collapsed p-3'>
                                                        <Row>
                                                            <Col xs="auto navbar-search-col-collapsed">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    id='search'
                                                                    value={searchQuery}
                                                                    onChange={handleInputChange}
                                                                    className="mr-sm-2 navbar-search-collapsed"
                                                                />
                                                                <img className='navbar-search-submit-collapsed' src={searchButton} onClick={handleSubmit}></img>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </>)
                                            :
                                            (
                                                <>
                                                    <Nav.Link ref={searchButtonRef} onClick={toggleSearchDropdown}>
                                                        <img
                                                            src={navbarSearch}
                                                            className="navbar-search-button"
                                                            alt="search"
                                                            onMouseEnter={(e) => e.currentTarget.src = navbarSearch2}
                                                            onMouseLeave={(e) => e.currentTarget.src = navbarSearch}
                                                        />
                                                    </Nav.Link>
                                                    <Nav.Link onClick={handleModal}>
                                                        <img
                                                            src={navbarLogin}
                                                            className="navbar-login-button"
                                                            alt="login"
                                                            onMouseEnter={(e) => e.currentTarget.src = navbarLogin2}
                                                            onMouseLeave={(e) => e.currentTarget.src = navbarLogin}
                                                        />
                                                    </Nav.Link>
                                                </>
                                            )}

                                        {showSearch && (
                                            <Form ref={dropdownRef} inline onSubmit={handleSubmit} className='search-dropdown p-3'>
                                                <Row>
                                                    <Col xs="auto navbar-search-col">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Search"
                                                            id='search'
                                                            value={searchQuery}
                                                            onChange={handleInputChange}
                                                            className="mr-sm-2 navbar-search"
                                                        />
                                                        <img className='navbar-search-submit' src={searchButton} onClick={handleSubmit}></img>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
                <LoginModal show={showModal} onHide={handleModal} />
            </Fragment>
        )
    } else if (isGroundwaterMap === true) {
        return (
            <></>
        )
    }

};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    Logout: userActions.Logout,
};

const connection = connect(mapState, actionCreators)(NavigationBar);
export { connection as NavigationBar };