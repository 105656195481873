import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(ScrollToTop);
export { connection as ScrollToTop };