import React, { Fragment, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';

export const UseDimensions = (targetRef) => {

    const getDimensions = () => {
      return {
        width: targetRef.current ? targetRef.current.offsetWidth : 0,
        height: targetRef.current ? targetRef.current.offsetHeight : 0,
      };
    };
  
    const [dimensions, setDimensions] = useState(getDimensions);
  
    const handleResize = () => {
      setDimensions(getDimensions());
    };
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    useLayoutEffect(() => {
      handleResize();
    }, []);
  
    return dimensions;
  }

/*function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(UseDimensions);
export { connection as UseDimensions };*/