//--------------------------------------------------
//Redux reducer for user session
//--------------------------------------------------

import { sessionConstants } from "./constants";

const INITIALSTATE = {
    defaultVar: '',
    clearance: localStorage.getItem('clearance') || 'unverified',
    token: localStorage.getItem('token') || "",
    error: null,
    useCaptcha: localStorage.getItem('useCaptcha') || null,
};

//User management
export function session(state = INITIALSTATE, action) {
    switch (action.type) {
        case sessionConstants.REQUEST:
            console.log("Action " + action.info)
            return state
        case sessionConstants.DefaultConstSUCCESS:
            return {...state, defaultVar: action.info};
        case sessionConstants.UseCaptcha:
            localStorage.setItem('useCaptcha', action.info);
            return {...state, useCaptcha: action.info};
        case sessionConstants.ResetEmailSuccess:
            return {...state, resetSent: true};
        case sessionConstants.FindUserSuccess:
            return {...state, userById: action.info};
        case sessionConstants.Logout:
            localStorage.clear();
            return state;
        case sessionConstants.CheckResetCodeSuccess:
            return {...state, accessToken: action.info};
            case sessionConstants.LoginRequest:
                return {
                    ...state,
                    error: null,
                };
            case sessionConstants.LoginSuccess:
                return {
                    ...state,
                    token: localStorage.getItem('token'),
                    clearance: action.clearance,
                };
            case sessionConstants.LoginFailure:
                return {
                    ...state,
                    error: action.error,
                };
        case sessionConstants.FindMetadataSuccess:
            return {...state, metaData: action.info};
        case sessionConstants.FindAdditionalMetaDataSuccess:
            return {...state, addMetaData: action.info};
        case sessionConstants.GetDataSuccess:
            return {...state, theData: action.info};
        default:
            return state;
    }
}