import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import * as d3 from "d3";
import { animated, SpringValue, useSpring } from "react-spring";
import { UseDimensions } from './useDimensions.js';
import { Tooltip } from './tooltip.js';

// Constants for margins and minimum dimensions
const MARGIN_X = 10;//150;
const MARGIN_Y = 70;//50;
//const MIN_WIDTH = 250; // Minimum width for the pie chart
//const MIN_HEIGHT = 150; // Minimum height for the pie chart

// Array of colors for slices
const colors = [
  "#e0ac2b",
  "#e85252",
  "#6689c6",
  "#9a6fb0",
  "#a53253",
  "#69b3a2",
];

const PieKey = ({ data, year, colors, county, noData }) => {
  return (
    <div className="pie-key">
      <h3>Water Use</h3>
      <p><b>County:</b> {county}</p>
      <p><b>Year:</b> {year}</p>
      <hr></hr>
      {noData ?
        <p>There is no water use data for this county and year. Please select a different county and/or year to generate a pie chart</p>
        :
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {data.map((d, i) => (
            <li key={d.type} className='key-item'>
              <span
                style={{
                  backgroundColor: colors[i],
                }}
              ></span>{d.type}: {d[year]} Acre-Feet</li>

          ))}
        </ul>
      }

    </div>
  );
};

const PieChart = ({ data, targetRef, year, county, noData }) => {
  // Sort by alphabetical to maximise consistency between dataset
  const sortedData = data.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

  // Use the useDimensions hook to get dimensions
  const { width, height } = UseDimensions(targetRef);

  // Ensure minimum width and height for the pie chart
  const effectiveWidth = width / 2;
  const effectiveHeight = height;

  // Reference for the container element
  const ref = useRef(null);

  // Calculate the radius of the pie chart
  const radius = Math.min(effectiveWidth - 2 * MARGIN_X, effectiveHeight - 2 * MARGIN_Y) / 2;

  // D3 pie generator to create the pie chart data
  const pieGenerator = d3
    .pie()
    .value((d) => d[year] || 0)
    .sort(null); // Do not apply any sorting, respect the order of the provided dataset since we already sorted it alphabetically

  // Generate pie chart slices
  const pie = pieGenerator(sortedData);

  const allPaths = pie.map((slice, i) => (
    <Slice key={slice.data.type} radius={radius} slice={slice} color={colors[i]} />
  ));

  //put together and return the pie chart with all the components 
  return (
    <div className='pie-chart'>
      <div className='pie-svg-container' ref={targetRef}>
        <svg width={effectiveWidth} height={effectiveHeight}>
          <g
            transform={`translate(${effectiveWidth / 2}, ${effectiveHeight / 2})`}
            className="container"
            ref={ref}
          >
            {allPaths}
          </g>
        </svg>
      </div>
      <PieKey data={sortedData} year={year} colors={colors} county={county} noData={noData} />
    </div>

  );
};

// Slice component to render each pie chart slice
const Slice = ({ slice, radius, color }) => {
  const arcPathGenerator = d3.arc(); // D3 arc generator for slice path

  // Use react-spring to animate slice angles
  const springProps = useSpring({
    to: {
      pos: [slice.startAngle, slice.endAngle],
    },
  });

  return (
    <animated.g className="slice">
      <animated.path
        d={springProps.pos.to((start, end) => {
          return arcPathGenerator({
            innerRadius: 0,
            outerRadius: radius,
            startAngle: start,
            endAngle: end,
          });
        })}
        fill={color}
      />
    </animated.g>
  );
};


function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {};

const connection = connect(mapState, actionCreators)(PieChart);
export { connection as PieChart };
