//--------------------------------------------------
//Immerse Hub Navbar
//--------------------------------------------------

import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginModal } from '../GeneralComponents/LoginModal';

import { Container, Navbar, Offcanvas, Nav } from 'react-bootstrap';
import { userActions } from '../../Actions';
import logo from '../../Images/immerse-logo.png';
import images from '../../Images/immerse-navbar-imgs.svg';

const ImmerseNavigationBar = ({ }) => {

    return (
        <Fragment>
            {['lg'].map((expand) => (
                <Navbar key={expand} expand={expand} className="nav-bg immerse-navbar" sticky="top">
                    <Container fluid>
                        <Navbar.Brand href="/immerse"><img className="immerse-navbar-brand" alt="" src={logo}/>{' '}</Navbar.Brand>
                        <Nav> 
                           {/* <img src={images}></img>*/}
                        </Nav>
                        {/*<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            backdrop={false}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>*/}
                    </Container>
                </Navbar>
            ))}
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    Logout: userActions.Logout,
};

const connection = connect(mapState, actionCreators)(ImmerseNavigationBar);
export { connection as ImmerseNavigationBar };