//--------------------------------------------------
//Template page to copy/paste for new pages
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
//import { Container, Row, Col } from 'react-bootstrap';
import * as ss from 'simple-statistics';
import { WaterMap } from '../DataVisualizations/watermap.js';

const GroundwaterMap = ({ session, crud, FindMetaData }) => {


  return (
    <Fragment>
      <WaterMap></WaterMap>
    </Fragment>
  )
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
  FindMetaData: userActions.FindMetaData
};

const connection = connect(mapState, actionCreators)(GroundwaterMap);
export { connection as GroundwaterMap };