import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import loginBg from '../../Images/login-bg.svg';
import NIDIS from '../../Images/NIDIS_Wildfire_Drought.png'
import GW from '../../Images/NM_GW.png'
import NOAA from '../../Images/NOAA_Water_Prediction.jpg'
import NW from '../../Images/NW_River_Forecasting_map.png'
import placeholder from '../../Images/placeholder.jpg'
import watershedMap from '../../Images/watershedMap.PNG'
import home from '../../Images/NCP6.JPG'
import waterAtlas from '../../Images/tvWaterAtlasLogo.png'
import directory from '../../Images/button-svg4.svg'
import TCP from '../../Images/TCP1.png'
import irrigationDistricts from '../../Images/irrigationDistricts.PNG'
import floatTheBoise from '../../Images/floatTheBoise.png'
import whitewaterPark from '../../Images/whitewaterPark.png'
import IDHW from '../../Images/IDHW.png'
import IDEQ from '../../Images/IDEQ.png'
import IDWR from '../../Images/IDWR.png'
import ASWCD from '../../Images/ASWCD.png'
import USGS from '../../Images/USGS.png'
import USDA from '../../Images/USDA.png'
import TheWatershed from '../../Images/TheWatershed.png'
import CityofNampa from '../../Images/CityofNampa.png'
import IWUA from '../../Images/IWUA.png'
import BREN from '../../Images/BREN.png'
import IRU from '../../Images/IRU.png'
import TroutUnlimited from '../../Images/TroutUnlimited.png'

const pages = [

    //Template
    // { name: '', content: '', path: '', category: '', img: blank, offsite: true },

    //Home
    { name: 'Home', content: 'Landing page of the Treasure Valley Water Hub.', path: '/', category: '', img: home, offsite: false },
    
    //Navbar
    { name: 'Data & Maps', content: 'Explore our interactive maps and data.', path: '/data-and-maps', category: 'Maps', img: placeholder, offsite: false },
    { name: 'Education', content: 'There are many educational resources to learn more about water in the Treasure Valley!', path: '/education', category: 'Curriculum', img: placeholder, offsite: false },
    { name: 'Premium', content: 'Become premium to gain access to our suite of tools.', path: '0', category: 'User Tools', img: placeholder, offsite: false },
    { name: 'Login', content: 'Login or create an account.', path: '/login/login', category: 'User Tools', img: placeholder, offiste: false },

    //Home Tiles
    { name: 'Groundwater Map', content: 'Groundwater trends, levels, data and more.', path: '/groundwaterMap', category: 'Maps', img: GW, offsite: false },
    { name: 'Voices of the Treasure Valley', content: 'Watch videos, read findings, and explore more information from the community.', path: '/voices', category: 'Articles', img: placeholder, offsite: false },
    { name: "Water Organization's Directory", content: 'Learn more about the water organizations in the Treasure Valley.', path: '/water-org-directory', category: 'Curriculum', img: directory, offsite: false },
    { name: 'TV Water Atlas', content: 'Treasure Valley Water Atlas. View narratives from the valley.', path: '/water-atlas', category: 'Curriculum', img: waterAtlas, offsite: true },
    { name: 'Find Your Irrigation District', content: 'Find your irrigation district and the contact information.', path: '/irrigation-districts', category: 'Maps', img: irrigationDistricts, offsite: false },

    //Map Collection Page
    { name: 'Drought and Wildfire', content: 'NIDIS Map. Use this interactive map below to explore wildfire, smoke, and air quality data alongside drought and climate conditions.', path: 'https://www.drought.gov/sectors/wildfire-management/interactive-map', category: 'Maps', img: NIDIS, offsite: true },
    { name: 'Northwest River Forecasting Center', content: 'Explore.', path: 'https://www.nwrfc.noaa.gov/rfc/', category: 'Maps', img: NW, offsite: true },
    { name: 'NOAA', content: 'Water Predictions.', path: 'https://water.noaa.gov/#@=-96.401081,38.1465724,2&b=topographic&g=obsFcst,1!1!1!1!1!1!1!1!1!1!1!1!1!1!1!0!0!0!0!0,0.5,1!1!1!1!0,0,0&ab=0,0,#D94B4A,1,1,1,#cccccc,1,0,0,#B243B1,1,0,0,#98E09A,1&a=hydrologic,0.35&s=0,0,0.9,0.9&n=false,#72afe9,0.9,0,0.9,0,0.9&p=false,0.75,0,7,0,1,2024,7,10,0&d=0,0,1,1,1,1,1,1,#006EFF,1,#006EFF,1,#006EFF&q=', category: 'Maps', img: NOAA, offsite: true },
    { name: 'USGS Water Atlas', content: 'The National Modeled Water Atlas will deliver routinely updated water availability information in the United States.', path: 'https://www.usgs.gov/nwc#overview', category: 'Maps', img: USGS, offsite: true },

    //Education Page
    { name: 'The Confluence Project', content: 'The Confluence Project (TCP) pairs scientific experts with Idaho high schools, promoting hands-on experience, data collection and degrees in the natural sciences.', path: '/tcp', category: 'Articles', img: TCP, offsite: false },
    { name: 'Learn About Idaho Watersheds', content: 'Explore story maps from across the basin.', path: '/watershed', category: 'Maps', img: watershedMap, offsite: false },
    { name: 'Float the Boise', content: 'Find current and up-to date information you need about safely floating the boise river this summer!', path: 'https://www.floattheboise.org/', category: 'Articles', img: floatTheBoise, offsite: true },
    { name: 'Boise Whitewater Park', content: 'Looking for a whitewater adventure in your own backyard? Look no further than Boise’s Whitewater Park!', path: 'https://www.boisewhitewaterpark.com/waveshaper-cam', category: 'Articles', img: whitewaterPark, offsite: true },

    //Organization's Directory
    { name: 'Idaho Department of Health and Welfare', content: 'Learn how to get your private well water tested. Learn what steps to take if your water sample test shows contaminants.', path: 'https://healthandwelfare.idaho.gov/health-wellness/environmental-health/drinking-water', category: 'Articles', img: IDHW, offsite: true },
    { name: 'Idaho Department of Environmental Quality', content: 'Learn about state water quality standards and regulations. Learn about safe waste disposal practices to protect source water', path: 'https://www.deq.idaho.gov/water-quality/', category: 'Articles', img: IDEQ, offsite: true },
    { name: 'Idaho Department of Water Resources', content: 'Learn about water rights in Idaho. Access IDWR’s water data on groundwater, rights, and supply', path: 'https://idwr.idaho.gov/', category: 'Articles', img: IDWR, offsite: true },
    { name: 'Ada County Soil & Water Conservation District', content: 'Find community education workshops like the Water Wise landscaping series. Learn about small grants for soil conservation.', path: 'https://www.adaswcd.org/', category: 'Articles', img: ASWCD, offsite: true },
    { name: 'US Geological Survey', content: 'Access StreamStats for data on streamflow in user-delineated drainages. View water data visualizations.', path: 'https://www.usgs.gov/mission-areas/water-resources', category: 'Articles', img: USGS, offsite: true },
    { name: 'USDA Natural Resource Conservation Service', content: 'Learn about water quality and conservation practices for farmers, ranchers, and forest landowners. Find assistance resources for conservation practices.', path: 'https://www.nrcs.usda.gov/conservation-basics/natural-resource-concerns/water', category: 'Articles', img: USDA, offsite: true },
    { name: 'City of Boise, Boise Watershed', content: 'Visit the WaterShed for a tour of Boise’s Wastewater renewal facility. Check out educational water programming for K-12 students.', path: 'https://www.boisewatershed.org/', category: 'Articles', img: TheWatershed, offsite: true },
    { name: 'City of Nampa, Waterworks Division', content: 'Learn about domestic and irrigation water for Nampa residents. Learn about Water Wise landscaping practices.', path: 'https://www.cityofnampa.us/292/Waterworks-Division', category: 'Articles', img: CityofNampa, offsite: true },
    { name: 'Treasure Valley Water Users Association', content: 'Learn about canal and lateral safety.', path: 'http://www.treasurevalleywaterusers.com/', category: 'Articles', img: IWUA, offsite: true },
    { name: 'Boise River Enhancement Network', content: 'Learn about the Boise River Enhancement Plan. Find events and volunteer opportunities.', path: 'https://www.boiseriverenhancement.org/', category: 'Articles', img: BREN, offsite: true },
    { name: 'Idaho Rivers United', content: 'Learn how IRU campaigns for river restoration, fish protection, and mining reform. Find events and volunteer opportunities.', path: 'https://www.idahorivers.org/', category: 'Articles', img: IRU, offsite: true },
    { name: 'Trout Unlimited', content: 'Learn about projects along the South Fork of the Boise River. Find events and volunteer opportunities with the local Ted Trueblood chapter.', path: 'https://tedtruebloodtu.org/?_gl=1%2A1dyzrka%2A_gcl_au%2ANzg4MzA3MDg0LjE3MjI1Mzc4NDU.%2A_ga%2AMTMyOTc3MzA2NS4xNzIyNTM3ODQ1%2A_ga_D3P9D95QQV%2AMTcyMzE0NDAzMi4yLjEuMTcyMzE0NDA3Mi4yMC4wLjA.', category: 'Articles', img: TroutUnlimited, offsite: true },
];

const searchPages = (query, filter, category) => {
    if (!query && !filter && !category) return pages;

    return pages.filter(page => {
        const matchesQuery = !query || page.name.toLowerCase().includes(query.toLowerCase()) || page.content.toLowerCase().includes(query.toLowerCase());
        const matchesFilter = !filter || filter.includes(page.category);
        const matchesCategory = !category || page.category === category;
        return matchesQuery && matchesFilter && matchesCategory;
    });
};


const SearchResults = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('q');
    const category = new URLSearchParams(location.search).get('category');
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (category) {
            setFilter(category);
        }
    }, [category]);

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        console.log(filter);
        console.log(selectedFilter);
        setFilter(selectedFilter === filter ? '' : selectedFilter);
    };

    const results = searchPages(query, filter ? [filter] : '', category);

    useEffect(() => {
        document.body.classList.add('results');
    
        return () => {
          document.body.classList.remove('results');
        };
      }, []);

    return (
        <Container fluid className="search-results">
            <img className='login-bg' src={loginBg}></img>
            <Row className="result-book justify-content-center">
                <Col sm={4} className="filter-section">
                    <h2>Search Results</h2>
                    <h3>Filters</h3>

                    <div className="search-filters">
                        <span className="checkmark"></span>
                        <input type="radio" id="filter1" name="filter" value="Maps" onClick={handleFilterChange} checked={filter === 'Maps'} disabled={category}/>
                        <label for="filter1">Maps</label><br/>
                        <input type="radio" id="filter2" name="filter" value="Articles" onClick={handleFilterChange} checked={filter === 'Articles'} disabled={category}/>
                        <label for="filter2">Articles</label><br/>
                        <input type="radio" id="filter3" name="filter" value="Curriculum" onClick={handleFilterChange} checked={filter === 'Curriculum'} disabled={category}/>
                        <label for="filter3">Curriculum</label><br/>
                        <input type="radio" id="filter4" name="filter" value="Interactive" onClick={handleFilterChange} checked={filter === 'Interactive'} disabled={category}/>
                        <label for="filter4">Interactive</label><br/>
                        <input type="radio" id="filter5" name="filter" value="User Tools" onClick={handleFilterChange} checked={filter === 'User Tools'} disabled={category}/>
                        <label for="filter5">User Tools</label>
                    </div>
                </Col>
                <Col sm={8}>
                    <div className="result-section">
                        {results.length > 0 ? (
                        <ul>
                            {results.map((result, index) => (
                                <div className="result-tab row" key={index}>
                                    <div className="img-section col-4">
                                        {result.img ? <img src={result.img}></img> : <></>}
                                    </div>
                                    <div className="col-8">
                                        <Link to={result.path} target={result.offsite ? '_blank' : ''}>
                                            <h2>{result.name}</h2>
                                        </Link>
                                        <p>{result.content}</p>
                                    </div>
                                </div>
                            ))}
                        </ul>
                        ) : (
                            <div className="result-tab row">
                                    <div className="img-section col-4">
                                        
                                    </div>
                                    <div className="col-8">
                                        <h2>No results found</h2>
                                        <p>Please search again</p>
                                    </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(SearchResults);
export { connection as SearchResults };