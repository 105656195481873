import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { IrrigationMap } from '../DataVisualizations/IrrigationMap.js';
import { Form, ListGroup, Container, Row, Col } from 'react-bootstrap';

import irrigationAddressSubmit from '../../Images/irrigation-address-submit.svg'
import irrigationDistrictContacts from '../../Data/irrigationDistrictContacts.js'

const IrrigationDistrictsMap = ({ }) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIDIST, setSelectedIDIST] = useState(null);
    const [streetAddress, setStreetAddress] = useState('NO CONTACT INFO');
    const [city, setCityName] = useState('NO CONTACT INFO');
    const [state, setStateName] = useState('NO CONTACT INFO');
    const [businessPhone, setBusinessPhone] = useState('NO CONTACT INFO');
    const [hasSearch, setHasSearch] = useState(false);

    const handleInputChange = async (event) => {
        const value = event.target.value || '';
        setInputValue(value);
        if (value.length > 2) {
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${value}`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching address suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.display_name);
        setSuggestions([]);
    };

    const handleSearch = () => {
        handleSetSearchQuery(inputValue);
        setSuggestions([]);
        setHasSearch(true);
    };

    const handleSetSearchQuery = (inputValue) => {
        setSearchQuery(inputValue);
      
        setTimeout(() => {
          setSearchQuery('');
        }, 10);
      };

    const handleFormSubmit = (event) => {
        event.preventDefault(); 
        handleSearch();
    };

    const handleIDIST = (idist) => {
        setSelectedIDIST(idist);
    };

    useEffect(() => {
    const district = irrigationDistrictContacts.find(district => district.OrganizationName === selectedIDIST);

    if (district) {
        setStreetAddress(district.StreetAddress);
        setCityName(district.City);
        setStateName(district.State);
        setBusinessPhone(district.BusinessPhone);
    }
}, [selectedIDIST]);

    return (
        <Fragment>
            <Container fluid className='irrigation-districts-container'>
                <Row className='irrgation-search-row'>
                    <Col md={7} className='irrigation-districts-map'>
                        <IrrigationMap
                            useIrrigationDistricts={true}
                            showSearchField={false}
                            mapX={43.6330111}
                            mapY={-116.586903}
                            zoomLevel={10}
                            searchQuery={searchQuery}
                            onIDIST={handleIDIST}
                        >
                        </IrrigationMap>
                    </Col>
                    <Col md={5}>
                        <div className='irrigation-address-search'>
                            <h1>What Irrigation District Am I In?</h1>
                            <label>Your Location</label>
                            <Form className='p-1 address-search-area' onSubmit={handleFormSubmit}>
                                <Form.Control
                                    type="text"
                                    placeholder="Address, Zip Code, City, etc"
                                    className={'mr-sm-2 navbar-search address-search-bar'}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <img src={irrigationAddressSubmit} onClick={handleSearch}></img>
                            </Form>
                            {suggestions.length > 0 && (
                                    <ListGroup className='address-suggestions-container'>
                                        {suggestions.map((suggestion) => (
                                            <ListGroup.Item key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)} className='address-suggestions'>
                                                {suggestion.display_name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                            )}
                            {selectedIDIST ? 
                            <Container fluid className='selected-irrigation-district'>
                                <Row className='justify-content-center'>
                                    <h3>{selectedIDIST}</h3>
                                    <hr/>
                                    <h4>Contact:</h4>
                                    <p>{streetAddress}, {city}, {state}</p>
                                    <p>{businessPhone}</p>
                                </Row>
                            </Container>
                            :
                            <>
                                {hasSearch ?
                                    <div className='selected-irrigation-district'>
                                        <h3>You do not have an irrigation district</h3>
                                    </div>
                                :
                                    <></>
                                }
                            </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(IrrigationDistrictsMap);
export { connection as IrrigationDistrictsMap };