import { socketConstants } from "./constants";

const INITIALSTATE = {
};

export function socket(state = INITIALSTATE, action) {
    switch (action.type) {
        case socketConstants.TestAction:
            console.log("Action " + action.info)
            return state
        default:
            return state;
    }
}