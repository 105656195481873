import { combineReducers } from 'redux';
import { session } from './session_reducer';
import { crud } from './crud_reducer';
import { alert } from './alert_reducer';
import { socket } from './socket_reducer';

//Combines all reducers for redux
const rootReducer = combineReducers({
    session,
    alert,
    crud,
    socket
});

export default rootReducer;