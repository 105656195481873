//--------------------------------------------------
//Map of the US React-Leaflet Map and GeoJSON
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import leafletPip from '@mapbox/leaflet-pip';
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, GeoJSON, Marker, Popup, LayersControl, LayerGroup } from 'react-leaflet';
import { Link } from "react-router-dom";

import adaCounty8 from '../../Data/adaCounty8';
import bannockCounty8 from '../../Data/bannockCounty8';
import bearLakeCounty8 from '../../Data/bearLakeCounty8';
import blaineCounty8 from '../../Data/blaineCounty8';
import boiseCounty8 from '../../Data/boiseCounty8';
import bonnevilleCounty8 from '../../Data/bonnevilleCounty8';
import boundaryCounty8 from '../../Data/boundaryCounty8';
import canyonCounty8 from '../../Data/canyonCounty8';
import cassiaCounty8 from '../../Data/cassiaCounty8';
import custerCounty8 from '../../Data/custerCounty8';
import fremontCounty8 from '../../Data/fremontCounty8';
import gemCounty8 from '../../Data/gemCounty8';
import idahoCounty8 from '../../Data/idahoCounty8';
import kootenaiCounty8 from '../../Data/kootenaiCounty8';
import latahCounty8 from '../../Data/latahCounty8';
import lemhiCounty8 from '../../Data/lemhiCounty8';
import owyheeCounty8 from '../../Data/owyheeCounty8';
import shoshoneCounty8 from '../../Data/shoshoneCounty8';
import valleyCounty8 from '../../Data/valleyCounty8';

import placeholder from '../../Images/placeholder.jpg'
import tvwateratlas1 from '../../Images/tvwateratlas1.jpg';
import tvwateratlas2 from '../../Images/tvwateratlas2.png';
import tvwateratlas3 from '../../Images/tvwateratlas3.png';
import tvwateratlas4 from '../../Images/tvwateratlas4.jpg';
import tvwateratlas5 from '../../Images/tvwateratlas5.png';
import tvwateratlas6 from '../../Images/tvwateratlas6.jpg';
import tvwateratlas7 from '../../Images/tvwateratlas7.jpg';

//Template
//{ lat: , lon: , name: '', watershed: '', image: placeholder, text: '', link: '' },

const markers = [
  { lat: 43.792696, lon: -115.127364, name:"Source to Sink", watershed: "North and Middle Forks Boise", image: tvwateratlas1, text: "Learn about the components of the water cycle and water balance in the context of the Treasure Valley.", link: 'https://storymaps.arcgis.com/stories/d3e5c0a2a73a4d65876456c5c531aa01' },
  { lat: 43.500497, lon: -116.410123, name:"History", watershed: "Lower Boise", image: tvwateratlas2, text: "Learn about the geological context of the Treasure Valley, and how agricultural irrigation over the past 150 years has raised the groundwater level in the valley.", link: 'https://storymaps.arcgis.com/stories/a9817b80e1394dc19e832704fd794bf8' },
  { lat: 43.526096, lon: -116.605121, name:"Water Law", watershed: "Lower Boise", image: tvwateratlas3, text: "Learn about Western water law and water rights in Idaho.", link: 'https://storymaps.arcgis.com/stories/3c40926e323c4cd39b9eec5012a2ad53' },
  { lat: 43.528690, lon: -116.043688, name:"Water Management", watershed: "Boise-Mores", image: tvwateratlas4, text: "Learn who the key players in water management and delivery are in the Treasure Valley.", link: 'https://storymaps.arcgis.com/stories/9656d06a91c2446ba21014bc5f4e9a4e' },
  { lat: 43.624053, lon: -116.564271, name:"Farmland & Urban Growth", watershed: "Lower Boise", image: tvwateratlas5, text: "Learn how agricultural and urban water use affects our groundwater supply.", link: 'https://storymaps.arcgis.com/stories/6cf50b67b6ed403ba6cb9988bf4c09f2' },
  { lat: 43.607094, lon: -115.853039, name:"Climate Change", watershed: "Boise-Mores", image: tvwateratlas6, text: "Learn how climate change affects the timing of surface water availability.", link: 'https://storymaps.arcgis.com/stories/b4dc55fa5dc6453faf32dbb543b57a1e' },
  { lat: 43.628247, lon: -116.446768, name:"Take Action", watershed: "Lower Boise", image: tvwateratlas7, text: "Learn how your community can take action to conserve and share water.", link: 'https://storymaps.arcgis.com/stories/0e2ec5f796724360b8ff0e3eb7cb5ca9' },
  { lat: 43.548751, lon: -115.718426, name: 'The South Fork of the Boise River', watershed: 'South Fork Boise', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/cd055c7fab7c48c48a48636e86f788c9' },
  { lat: 46.398694, lon: -116.988227, name: 'Lewiston Clarkston Valley Aquifers', watershed: 'Clearwater', image: placeholder, text: '', link: 'https://lcvaquifers-idaho.hub.arcgis.com/' },
  { lat: 47.787078, lon: -116.363367, name: 'Abandoned Mines', watershed: "Upper Coeur d'Alene", image: placeholder, text: '', link: 'https://trout.maps.arcgis.com/apps/Cascade/index.html?appid=75930d8741f3440ebf1633c217879f3c' },
  { lat: 43.495524, lon: -115.400985, name: 'Science at Trout Unlimited', watershed: 'South Fork Boise', image: placeholder, text: '', link: 'https://trout.maps.arcgis.com/apps/Cascade/index.html?appid=d0e6e266906e46ebb1d230ef00636d68' },
  { lat: 44.374421, lon: -114.723365, name: 'The Yankee Fork', watershed: 'Upper Salmon', image: placeholder, text: '', link: 'https://trout.maps.arcgis.com/apps/Cascade/index.html?appid=151e94de7f6f4e869478e1d5f24d2926' },
  { lat: 43.335863, lon: -111.210291, name: 'Snake River Headwaters', watershed: 'Palisades', image: placeholder, text: '', link: 'https://trout.maps.arcgis.com/apps/MapSeries/index.html?appid=fcc6a78a58814f1dbd373a937c519232' },
  { lat: 48.384275, lon: -116.261211, name: 'Waters of the US', watershed: 'Pend Oreille Lake', image: placeholder, text: '', link: 'https://trout.maps.arcgis.com/apps/Cascade/index.html?appid=1389dcb368434118b09244e837e670a5' },
  { lat: 43.874574, lon: -116.923664, name: 'Conservation Atlas: Wild Steelhead', watershed: 'Middle Snake-Payette', image: placeholder, text: '', link: 'https://www.wildsteelheaders.org/our-work/conservation/' },
  { lat: 43.500551, lon: -111.424371, name: 'South Fork of the Snake River', watershed: 'Palisades', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/9ab8d7c08d464f4f9aa8a13db2da49b9' },
  { lat: 46.503985, lon: -116.505950, name: 'Clearwater River', watershed: 'Clearwater', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/fff54e036c984b9fabd616516b80f702' },
  { lat: 48.827633, lon: -116.873699, name: 'Lessons From One River', watershed: 'Priest', image: placeholder, text: '', link: 'https://www.arcgis.com/apps/Cascade/index.html?appid=8b5e75be60de43cfbabae184440d7621' },
  { lat: 45.183903, lon: -114.016519, name: 'Upper Salmon Basin', watershed: 'Middle Salmon-Panther', image: placeholder, text: '', link: 'https://modelwatershed.idaho.gov/the-basin/' },
  { lat: 45.156342, lon: -115.268375, name: 'Idaho Fishing Planner', watershed: 'Lower Middle Fork Salmon', image: placeholder, text: '', link: 'https://idfg.idaho.gov/ifwis/fishingplanner/mapcenter/' },
  { lat: 42.758140, lon: -113.341278, name: 'Irrigated Lands in Idaho', watershed: 'Lake Walcott', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/8e58f1d902d744ea83c8b05b7403fea3' },
  { lat: 46.331855, lon: -116.438146, name: 'Camas to Condors', watershed: 'Clearwater', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/c24b52bf5e0f421188eeddb001dabe34' },
  { lat: 43.617946, lon: -116.295428, name: 'Water Supply Report', watershed: 'Lower Boise', image: placeholder, text: '', link: 'https://idaho-capital-investment-hub-vnagis.hub.arcgis.com/apps/9f3c4aa6d74d470cbdb1e501ef41865d/explore' },
  { lat: 43.561101, lon: -116.122723, name: 'Lower Boise: Green Stormwater Infrastructure', watershed: 'Lower Boise', image: placeholder, text: '', link: 'https://boise.maps.arcgis.com/apps/MapJournal/index.html?appid=051136d2476a42b88db86d683cde5ecd' },
  { lat: 43.651753, lon: -116.296899, name: 'Hyatt Hidden Lakes', watershed: 'Lower Boise', image: placeholder, text: '', link: 'https://boise.maps.arcgis.com/apps/MapSeries/index.html?appid=75656146c4b94b89beee76d7cc835074' },
  { lat: 47.249742, lon: -115.955449, name: 'Saint Joe River', watershed: 'St. Joe', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/282e5b3b012a494693fb883488bac705' },
  { lat: 46.099548, lon: -116.921857, name: 'Dams and Salmon', watershed: 'Lower Snake-Asotin', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/7821a32e3e244e408eae8f24ac1f5d1c' },
  { lat: 44.386989, lon: -111.442752, name: 'Greater Yellowstone Area Beaver Restoration Assessment Tool', watershed: 'Upper Henrys', image: placeholder, text: '', link: 'https://usuonline.maps.arcgis.com/apps/Cascade/index.html?appid=87b2ef2a149b4cec918573db36fd5edc' },
  { lat: 45.533126, lon: -113.093096, name: 'Idaho Beaver Restoration Assessment Tool', watershed: 'Big Hole', image: placeholder, text: '', link: 'http://etal.joewheaton.org/brat-idaho.html' },
  { lat: 42.924656, lon: -112.482621, name: 'Green Pocatello', watershed: 'Portneuf', image: placeholder, text: '', link: 'https://isu.maps.arcgis.com/apps/MapJournal/index.html?appid=668f8c436eb44c85af780921c7351644' },
  { lat: 47.729724, lon: -116.297471, name: 'Beneficial Use Reconnaissance Program (BURP)', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/6d5d59e3348a421581935f3361e82595' },
  { lat: 43.613171, lon: -116.244568, name: "Idaho's 2022 Integrated Report", watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/67e18aab48ea4377a8004cdb5f19a66c' },
  { lat: 47.674586, lon: -116.723083, name: 'Fernan Lake Watershed', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/d77eb1c21bfc421cb40f11aa1b53c69b' },
  { lat: 47.621240, lon: -116.261583, name: "North Fork Coeur d'Alene River Subbasin", watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/615939f9835042788e38af6c87ad3fe8' },
  { lat: 43.634049, lon: -116.231499, name: 'Idaho DEQ Cyanobacteria Harmful Algal Bloom Information', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/4990590489e04fc18ff1ce0ddc01099a' },
  { lat: 48.880377, lon: -116.159298, name: 'Kootenai & Moyie Rivers Tributary Stream Temperatures', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/f8d2ebacf8e343998c762436d1ad775d' },
  { lat: 48.898114, lon: -116.945360, name: 'Priest Basin Stream Temperatures', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/b6013b2338274932b08f9be594cc9ed8' },
  { lat: 47.513806, lon: -116.741766, name: "DEQ Coeur d'Alene Regional Office Harmful Algal Blooms", watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/751de73bca6f446997d4fc67242258a7' },
  { lat: 47.695110, lon: -116.726481, name: 'DEQ TMDL & 5-Year Review Overview', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/d6e3b2fcf4124ac69d465f6bd0bbfa4a' },
  { lat: 47.791052, lon: -116.453662, name: 'Lower Clark Fork', watershed: '', image: placeholder, text: '', link: 'https://storymaps.arcgis.com/stories/7aa6abc9205d4ea9bdd8232f319a8df5' },
];

const geoJSONLayers = [adaCounty8, bannockCounty8, bearLakeCounty8, blaineCounty8,
  boiseCounty8, bonnevilleCounty8, boundaryCounty8, canyonCounty8, cassiaCounty8,
  custerCounty8, fremontCounty8, gemCounty8, idahoCounty8, kootenaiCounty8,
  latahCounty8, lemhiCounty8, owyheeCounty8, shoshoneCounty8, valleyCounty8
];

const GeoJSONFormat = ({ data, style }) => {
  const map = useMap();

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.name) {
      layer.bindTooltip(feature.properties.name, {
        sticky: true,
        direction: 'auto',
        className: 'custom-tooltip'
      });

      layer.on({
        click: () => handleClick(feature)
      });
    }
  }

  const handleClick = (feature) => {

    const bounds = L.geoJSON(feature).getBounds();
    const center = bounds.getCenter();
    map.flyTo(center, 9);
  };

  return <GeoJSON data={data} style={style} onEachFeature={onEachFeature} />
};

const SearchField = (props) => {
  const map = useMap();

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: props.provider,
      style: "bar",
      marker: {
        icon: new L.icon({
          iconUrl:
            "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
          iconSize: [25, 41],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40]
        })
      },
      ...props
    });
    map.addControl(searchControl);

    const onShowLocation = (data) => {
      if (data.location && data.location.x && data.location.y) {
        const latlng = {
          lat: data.location.y,
          lng: data.location.x,
        };

        for (const layer of geoJSONLayers) {
          const results = leafletPip.pointInLayer(latlng, L.geoJSON(layer));
          if (results.length > 0) {
            const feature = results[0].feature;
            if (feature.properties && feature.properties.name) {
              props.setYourWatershed(feature.properties.name);
              break;
            }
          }
        }

      }
    };

    map.on('geosearch/showlocation', onShowLocation);

    return () => map.removeControl(searchControl);
    map.off('geosearch/showlocation', onShowLocation);
  }, [map, props]);

  return null;
};

const WatershedMap = ({ }) => {
  const prov = new OpenStreetMapProvider();
  const [yourWatershed, setYourWatershed] = useState('');

  //map variables
  const center = [45.4766, -114.2855];
  const bounds = [
    [center[0] - 7.0, center[1] - 7.0], // Southwest corner
    [center[0] + 7.0, center[1] + 7.0]  // Northeast corner
  ];

  const markerIcon = L.icon({
    iconUrl: require('../../Images/map-marker-icon.png'),
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [2, -40]
  });

  const geoJSONWaterStyle = {
    color: "#642A86",
    weight: 2,
    fillColor: "var(--Icons-White-Blue, #E9EFFF)",
    fillOpacity: 0.3
  };

  return (
    <Fragment>
        <div className='watershed-map-container'>
          <MapContainer center={center} zoom={7} maxBounds={bounds} maxBoundsViscosity={1.0} minZoom={6}>
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />

            {yourWatershed.length < 1 ?
              <SearchField
                provider={prov}
                setYourWatershed={setYourWatershed}
                showMarker={true}
                showPopup={true}
                popupFormat={({ query, result }) => result.label}
                retainZoomLevel={true}
                animateZoom={true}
                autoClose={true}
                searchLabel={"Enter address"}
                keepResult={true}
                className='address-search'
              /> :
              ''
            }

            <GeoJSONFormat data={adaCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={bannockCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={bearLakeCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={blaineCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={boiseCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={bonnevilleCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={boundaryCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={canyonCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={cassiaCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={custerCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={fremontCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={gemCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={idahoCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={kootenaiCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={latahCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={lemhiCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={owyheeCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={shoshoneCounty8} style={geoJSONWaterStyle} />
            <GeoJSONFormat data={valleyCounty8} style={geoJSONWaterStyle} />
            {markers.map((marker, index) => (
              <Marker key={index} position={[marker.lat, marker.lon]} icon={markerIcon}>
              <Popup>
                <div className='watershed-popup'>
                  <h2>{marker.name}</h2>
                  <p>
                    {marker.text}                  
                    <Link to={marker.link} target='_blank'>
                      <span> See More &gt;</span>
                    </Link>
                  </p>
                  <Link to={marker.link} target='_blank'>
                    <img src={marker.image} alt={marker.name} />
                  </Link>
                </div>
              </Popup>
            </Marker>
            ))}
          </MapContainer>
        </div>
    </Fragment>
  )
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(WatershedMap);
export { connection as WatershedMap };