//--------------------------------------------------
//Rendered Component for user's account information
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import { Edit } from '@mui/icons-material'
import Modal from 'react-bootstrap/Modal';

const Account = ({ session, FindUserById, DeleteAccount, EditAccount, SendVerificationEmail }) => {
    const userId = JSON.parse(atob(session.token.split('.')[1])).subject; //Get user id from session token
    const [showModal, setShowModal] = useState();
    const [editEmail, setEditEmail] = useState(false);
    const [editName, setEditName] = useState(false);
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [password, setPassword] = useState("");

    useEffect(() => {
        if(session.token && userId) FindUserById(session.token, userId);
    }, []); 

    useEffect(() => {
        if(session.userById) {
            setName(session.userById.name)
            setEmail(session.userById.email)
        }
    }, [session]); 

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const cancelName = () => {
        setEditName(false)
        setName(session.userById.name)
    };
    const cancelEmail = () => {
        setEditEmail(false)
        setEmail(session.userById.email)
    };

    const editAccount = () => {
        EditAccount(session.token, name, email, password)
    };

    return (
        <Fragment>
            <div className='main-container account'>
            <EditAccountModal showModal={showModal} handleClose={handleClose} setPassword={setPassword} password={password} editAccount={editAccount} />
                <div className='border-container'>
                    <h1>Account</h1>
                </div>

                <div className='container'>
                    {session.userById ? 
                    <Fragment>
                        {editName ? 
                            <Fragment>
                                <input className='center' type='text' value={name} onChange={(e) => setName(e.target.value)}></input>
                                <button onClick={() => cancelName()}>Cancel</button>
                            </Fragment>
                        : 
                            <Fragment>
                                <h4>Name: {session.userById.name}</h4> 
                                <button onClick={() => setEditName(true)}><Edit/></button>
                            </Fragment>
                        }
                        {editEmail ? 
                            <Fragment>
                                <input className='center' type='text' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                <button onClick={() => cancelEmail(false)}>Cancel</button>
                            </Fragment>
                        : 
                            <Fragment>
                                <h4>Email: {session.userById.email}</h4> 
                                <button onClick={() => setEditEmail(true)}><Edit/></button>
                            </Fragment>
                        }
                    </Fragment>
                    : "Loading..."}
                </div>

                <button onClick={() => DeleteAccount(session.token, userId)}>Delete Account</button>
                <button onClick={() => SendVerificationEmail(session.token)}>Verify Email</button>

                {editEmail || editName ? 
                    <button onClick={() => handleShow()}>Save</button>
                : null}

            </div>
        </Fragment>
    )
};

const EditAccountModal = ({ showModal, handleClose, setPassword, password, editAccount }) => (
    <Modal show={showModal} onHide={() => handleClose()} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>Update Account Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Type in your password to confirm your changes. 
            <input className='center' type='text' value={password} onChange={(e) => setPassword(e.target.value)}></input>
        </Modal.Body>
        <Modal.Footer>
            <button onClick={() => editAccount()}>Submit</button>
        </Modal.Footer>
    </Modal>
);

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    FindUserById: userActions.FindUserById,
    DeleteAccount: userActions.DeleteAccount,
    EditAccount: userActions.EditAccount,
    SendVerificationEmail: userActions.SendVerificationEmail,
};

const connection = connect(mapState, actionCreators)(Account);
export { connection as Account };